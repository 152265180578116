import { roles } from "../costants";

export const availableListRoles = (role: string, t: any) => {
  switch (role) {
    case roles.superadmin:
      return [
        "customers",
        "manutentori",
        "responsabili",
        "installers",
        "aziende",
        "admins",
      ];
    case roles.admin:
      return [
        "customers",
        "manutentori",
        "responsabili",
        "installers",
        "aziende",
      ];
    case roles.company:
      return ["responsabili", "manutentori", "installers", "customers"];
    case roles.respMaintainer:
      return ["manutentori"];
    case roles.maintainer:
      return [];
    case roles.installer:
      return [];
    case roles.customer:
      return [];
    default:
      return [];
  }
};

export const availableRoles = (role: string, t: any) => {
  switch (role) {
    case roles.superadmin:
      return availableRolesForSuperAdmin(t);
    case roles.admin:
      return availableRolesForAdmin(t);
    case roles.company:
      return availableRolesForCompany(t);
    case roles.respMaintainer:
      return availableRolesForRespMaintainer(t);
    case roles.maintainer:
      return avaliableRolesForMaintainer;
    case roles.installer:
      return avaliableRolesForInstaller;
    case roles.customer:
      return avaliableRolesForEndUser;
    default:
      return [];
  }
};

export const availableRolesForSuperAdmin = (t: any) => [
  {
    label: `${t("admin")}`,
    value: roles.admin,
  },
  {
    label: `${t("company")}`,
    value: roles.company,
  },
  {
    label: `${t("mantainerResponsible")}`,
    value: roles.respMaintainer,
  },
  {
    label: `${t("mantainer")}`,
    value: roles.maintainer,
  },
  {
    label: `${t("installer")}`,
    value: roles.installer,
  },
  {
    label: `${t("endUser")}`,
    value: roles.customer,
  },
];

export const availableRolesForAdmin = (t: any) => [
  {
    label: `${t("company")}`,
    value: roles.company,
  },
  {
    label: `${t("mantainerResponsible")}`,
    value: roles.respMaintainer,
  },
  {
    label: `${t("mantainer")}`,
    value: roles.maintainer,
  },
  {
    label: `${t("installer")}`,
    value: roles.installer,
  },
  {
    label: `${t("endUser")}`,
    value: roles.customer,
  },
];

export const availableRolesForCompany = (t: any) => [
  {
    label: `${t("mantainerResponsible")}`,
    value: roles.respMaintainer,
  },
  {
    label: `${t("mantainer")}`,
    value: roles.maintainer,
  },
  {
    label: `${t("installer")}`,
    value: roles.installer,
  },
  {
    label: `${t("endUser")}`,
    value: roles.customer,
  },
];

export const availableRolesForRespMaintainer = (t: any) => [
  {
    label: `${t("mantainer")}`,
    value: roles.maintainer,
  },
];

export const avaliableRolesForMaintainer = [];

export const avaliableRolesForInstaller = [];

export const avaliableRolesForEndUser = [];
