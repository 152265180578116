import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCompanies, getUser } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import { getElevators } from "../../../api/services/elevatorService";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { render } from "@testing-library/react";

type OverviewProps = {
  title: string;
  role: string;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: (id: string) => string;
};

const CompaniesOverview: React.FC<OverviewProps> = ({
  title,
  role,
  setBreadcrumb,
  link,
}) => {
  document.body.style.backgroundColor = "#ffffff";

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [elevators, setElevators] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resElevators = await getElevators(role);
        const resCompanies = await getCompanies();

        if (
          resElevators &&
          resElevators.installations &&
          resCompanies &&
          resCompanies.aziende
        ) {
          const installations = resElevators.installations;
          const companies = resCompanies.aziende;

          const companiesMap: any = {};
          const adminCache: any = {};

          for (const company of companies) {
            let adminName: string = "";
            let adminEmail = "";

            if (role === "superadmin") {
              if (adminCache[company.created_by]) {
                const cachedAdmin = adminCache[company.created_by];
                adminName = cachedAdmin.name;
                adminEmail = cachedAdmin.email;
              } else {
                const companyAdmin = await getUser(company.created_by);
                if (companyAdmin && companyAdmin.user) {
                  adminName = companyAdmin.user.name;
                  adminEmail = companyAdmin.user.email;
                  adminCache[company.created_by] = {
                    name: adminName,
                    email: adminEmail,
                  };
                }
              }
            }

            companiesMap[company.uid] = {
              name: company.name,
              email: company.email,
              adminName: adminName,
              adminEmail: adminEmail,
            };
          }

          const installationsWithCompanyNames = installations.map(
            (installation: any) => ({
              ...installation,
              company: companiesMap[installation.azienda]?.name
                ? `${companiesMap[installation.azienda]?.name} - ${
                    companiesMap[installation.azienda]?.email
                  }`
                : "N/A",
              admin: companiesMap[installation.azienda]?.adminName
                ? `${companiesMap[installation.azienda]?.adminName} - ${
                    companiesMap[installation.azienda]?.adminEmail
                  }`
                : "N/A",
            })
          );

          setElevators(installationsWithCompanyNames);
        } else {
          setElevators([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setElevators([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  let columns = [
    {
      headerName: `${t("company")}`,
      field: "company",
      flex: 1.2,
      filterable: true,
    },
    {
      headerName: `${t("impiantoName")}`,
      field: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          <Link style={{ color: "black" }} to={link(params.row.id)}>
            {params.row.name}
          </Link>
        </div>
      ),
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_number || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.installation_info?.address || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("model")}`,
      field: "model",
      flex: role === "superadmin" ? 0.7 : 0.5,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">{t(params?.row?.model) || "N/A"}</div>
      ),
    },
  ];

  if (role === "superadmin") {
    columns = [
      {
        headerName: `${t("admin")}`,
        field: "admin",
        flex: 1.2,
        filterable: true,
      },
      ...columns,
    ];
  }

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={`${t(title)}`} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomTable columns={columns} rows={elevators} />
      </Grid>
    </Grid>
  );
};

export default CompaniesOverview;
