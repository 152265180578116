import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { configurationParamsUrl, mainUrl, roles } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import {
  createElevator,
  getBoardType,
  getContainers,
} from "../../../api/services/elevatorService";
import { useSnackbar } from "notistack";
import CustomSelect from "../../custom/CustomSelect";
import CustomLoading from "../../custom/CustomLoading";
import CustomFeedback from "../../custom/CustomFeedback";
import { getAzElevator, idAssociation } from "../../../api/services/azService";
import CustomText from "../../custom/CustomText";
import { getUsers } from "../../../api/services/userService";

type CreateElevatorProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  actualRole: string;
};

const CreateElevator: React.FC<CreateElevatorProps> = ({
  setBreadcrumb,
  actualRole,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("createDevice")}
      </Typography>,
    ]);
  }, []);

  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  useEffect(() => {
    if (actualRole === roles.superadmin || actualRole === roles.admin) {
      getUsers("aziende").then((res) => {
        if (res && res.aziende) {
          setAllCompanies(res.aziende);
        }
      });
    }
  }, [actualRole]);

  const [types, setTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    getBoardType().then((res) => {
      if (res && res.board_type) {
        setTypes(res.board_type);
      }
      setLoading(false);
    });
  }, []);

  const [load, setLoad] = useState<boolean>(true);
  const [containers, setContainers] = useState<any[]>([]);
  useEffect(() => {
    getContainers(actualRole).then((res) => {
      if (res && res.installations_containers) {
        setContainers(res.installations_containers);
      }
      setLoad(false);
    });
  }, []);

  const [values, setValues] = useState({
    installation: "",
    system_number: "",
    system_serial_number: "",
    address: "",
    fw: "",
    apn: "iot.secure",
    id_az: "",
    azienda: "",
  });

  const handleSubmit = () => {
    if (values.system_number) {
      createElevator({
        customer_destination_id: "TBD",
        name: values.installation,
        sys_num: values.system_number,
        sys_serial_num: values.system_serial_number,
        model: values.fw,
        installation_info: {
          address: values.address,
          city: "",
          province: "",
        },
        phone_operator: values.apn,
        installations_container_id:
          containers.filter((c: any) => c.name === values.installation).length >
          0
            ? containers.filter((c: any) => c.name === values.installation)[0]
                .id
            : "",
        azeta_gestionale_id: az.id || values.id_az,
        azienda: values.azienda,
      }).then((res: any) => {
        if (res && res.installation && res.installation.id) {
          enqueueSnackbar(t("createElevatorSuccess"), { variant: "success" });
          if (az.id) {
            idAssociation(res.installation.id, values.system_number);
          }
          navigate(configurationParamsUrl(res.installation.id));
        } else {
          enqueueSnackbar(`${t("createElevatorError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    } else {
      enqueueSnackbar(t("systemNumberRequired"), { variant: "error" });
    }
  };

  const [az, setAz] = useState<{
    id: string;
    getDone: boolean;
  }>({ id: "", getDone: false });

  const handleCheckAz = () => {
    getAzElevator(az.id).then((res: any) => {
      if (res && res.name) {
        setValues({
          installation: res.name || "",
          system_number: "",
          system_serial_number: res.sys_serial_num || "",
          address: res?.installation_info?.address || "",
          fw: "",
          apn: "iot.secure",
          id_az: az.id,
          azienda: "",
        });
        setAz({ ...az, getDone: true });
      } else {
        enqueueSnackbar(t("azError"), { variant: "error" });
      }
    });
  };

  if (loading || load) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("createDevice")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      {types.length === 0 && (
        <Grid item xs={12}>
          <CustomFeedback label={t("noBoardAssigned")} type="error" />
        </Grid>
      )}
      {az.getDone ? (
        <Grid item xs={12} md={8}>
          <CustomCard
            content={
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("firmware")}`}
                    value={values.fw}
                    options={[
                      ...types.map((tt: string) => {
                        return {
                          label: t(tt),
                          value: tt,
                        };
                      }),
                    ]}
                    handleChange={(e) =>
                      setValues({ ...values, fw: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={[
                      ...containers.map(
                        (c: {
                          id: string;
                          name: string;
                          description: string;
                          created_at: string;
                          deleted_at: string;
                        }) => c.name
                      ),
                    ]}
                    defaultValue={values.installation}
                    freeSolo
                    getOptionLabel={(option) => option}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setValues({ ...values, installation: `${newValue}` });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("installationName")}
                        value={values.installation}
                        onChange={(e) =>
                          setValues({ ...values, installation: e.target.value })
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("systemNumber")}
                    value={values.system_number}
                    handleChange={(e) =>
                      setValues({ ...values, system_number: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("systemSerialNumber")}
                    value={values.system_serial_number}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        system_serial_number: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("address")}
                    value={values.address}
                    handleChange={(e) =>
                      setValues({ ...values, address: e.target.value })
                    }
                  />
                </Grid>
                {actualRole === roles.superadmin && (
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      options={
                        (allCompanies || []).map((company) => ({
                          value: company.uid,
                          label: `${company.name} (${company.email})`,
                        })) || []
                      }
                      label={`${t("company")} (facoltativo)`}
                      value={values.azienda}
                      handleChange={(e) =>
                        setValues({ ...values, azienda: e.target.value })
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label={t("idGestionale")}
                    value={values.id_az}
                    handleChange={(e) =>
                      setValues({ ...values, id_az: e.target.value })
                    }
                    disabled
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                label={t("reset")}
                onClick={() =>
                  setValues({
                    installation: "",
                    system_number: "",
                    system_serial_number: "",
                    address: "",
                    fw: "",
                    apn: "iot.secure",
                    id_az: "",
                    azienda: "",
                  })
                }
                confirm
                type="outlined"
              />,
              <CustomButton
                fullWidth
                label={t("ok")}
                onClick={handleSubmit}
                type="contained"
              />,
            ]}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={8}>
          <CustomCard
            content={
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomText label={t("azInfo")} type="subtitle1" />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    label={t("azId")}
                    value={az.id}
                    handleChange={(e) =>
                      setAz({ ...az, id: e.target.value, getDone: false })
                    }
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                label={t("manualInsert")}
                onClick={() => setAz({ ...az, getDone: true })}
                type="outlined"
              />,
              <CustomButton
                fullWidth
                label={t("ok")}
                onClick={handleCheckAz}
                type="contained"
                disabled={!az.id}
              />,
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default CreateElevator;
