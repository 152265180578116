import React, { useEffect } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { chartPageUrl, mainUrl } from "../costants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomText from "../../custom/CustomText";
import successImage from "../../../images/success.svg";
import CustomFeedback from "../../custom/CustomFeedback";

type ConfigurationEndProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const ConfigurationEnd: React.FC<ConfigurationEndProps> = ({
  setBreadcrumb,
}) => {
  const { id } = useParams(); //device-id
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const warningNoSim = queryParams.get("warningNoSim") === "true";

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("configuration")}
      </Typography>,
    ]);
  }, []);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("configuration")}`}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <CustomText type="h4" label={`${t("finishConfiguration")}`} />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <img height={300} src={successImage} alt="success" />
              </Grid>
              <Grid item>
                <CustomText
                  type="h6"
                  label={`${t("finishConfigurationDescription")}`}
                />
              </Grid>
              {warningNoSim && (
                <Grid item>
                  <CustomFeedback type="warning" label={t("noSimWarning")} />
                </Grid>
              )}
              <Grid item container spacing={2}>
                <Grid item xs={12} md>
                  <CustomButton
                    fullWidth
                    label={t("dashboard")}
                    onClick={() => navigate(chartPageUrl(id!))}
                    type="contained"
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationEnd;
