import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import CustomCard from "../../custom/CustomCard";
import CustomList from "../../custom/CustomList";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomTag from "../../custom/CustomTag";
import { companyListToChartUrl, mainUrl, roles } from "../costants";
import { Link } from "react-router-dom";
import {
  getCompanyElevators,
  getElevators,
} from "../../../api/services/elevatorService";
import { getUser } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import { Elevator, Warning } from "@mui/icons-material";
import { render } from "@testing-library/react";

type ElevatorListToChartProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  previousLink: string;
  previousTitle: string;
  nextLink: (id: string) => string;
  companyInfo: boolean;
  title: string;
  role: string;
};

const ElevatorListToChart: React.FC<ElevatorListToChartProps> = ({
  setBreadcrumb,
  previousLink,
  previousTitle,
  nextLink,
  companyInfo,
  title,
  role,
}) => {
  const { id } = useParams(); //company-id oppure "all"
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      ...(companyInfo
        ? [
            <Link style={{ color: "#ffffff" }} key="2" to={previousLink}>
              {t(previousTitle)}
            </Link>,
          ]
        : []),
      <Typography color="#ffffff" key="3">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading1, setLoading1] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  const [company, setCompany] = useState({
    name: "",
    email: "",
    phone: "",
  });
  useEffect(() => {
    if (companyInfo) {
      getUser(id!).then((res: any) => {
        if (res && res.user) {
          setCompany({
            name: res.user?.name,
            email: res.user?.email,
            phone: res.user?.user_info?.phone,
          });
        }
        setLoading1(false);
      });
    } else {
      setLoading1(false);
    }
    if (id !== "all") {
      //fetch elevators by company
      getCompanyElevators(id!).then((res: any) => {
        if (res && res.installations) {
          setElevators([...res.installations]);
        }
        setLoading2(false);
      });
    }
  }, []);

  useEffect(() => {
    if (id === "all") {
      // Funzione per controllare il localStorage e chiamare l'endpoint
      const checkLocalStorage = () => {
        const tmp = localStorage.getItem("zerynth_deleting_elevator");
        if (!tmp) {
          clearInterval(interval); // Pulisce l'interval dopo il primo controllo
          getElevators(role).then((res) => {
            if (res && res.installations) {
              setElevators([...res.installations]);
            }
            setLoading2(false);
          });
        }
      };

      // Esegui la funzione di controllo ogni secondo
      const interval = setInterval(checkLocalStorage, 1000);

      // Esegui il controllo iniziale quando il componente viene montato
      checkLocalStorage();

      // Pulisci l'interval quando il componente viene smontato
      return () => {
        clearInterval(interval);
      };
    }
  }, []); // L'array vuoto come dipendenza indica che questo effetto si verifica solo una volta

  if (loading1 || loading2) {
    return <CustomLoading />;
  }

  const customerColumns = [
    {
      headerName: `${t("impiantoName")}`,
      field: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div
          className="rendercellclass"
          style={{ display: "flex", alignItems: "center" }}
        >
          {params.row?.id_elevate && (
            <Elevator textDecoration="Scheda elevate collegata" />
          )}
          <Link style={{ color: "black" }} to={nextLink(params.row.id)}>
            {params.row.name}
          </Link>
        </div>
      ),
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_number || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("systemSerialNumber")}`,
      field: "system_serial_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_serial_number || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.installation_info?.address || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("status")}`,
      field: "status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          <CustomTag
            label={String(params.row.status).toLocaleUpperCase()}
            type="filled"
            color={
              String(params.row.status).toLocaleLowerCase() === "online"
                ? "green"
                : "red"
            }
          />
        </div>
      ),
    },
  ];

  const installerColumns = [
    {
      headerName: `${t("name")}`,
      field: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link style={{ color: "black" }} to={nextLink(params.row.id)}>
          {params.row.name}
        </Link>
      ),
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_number || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("systemSerialNumber")}`,
      field: "system_serial_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_serial_number || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("idGestionale")}`,
      field: "idGestionale",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.id_gestionale ? params.row.id_gestionale : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.installation_info?.address || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("status")}`,
      field: "status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          <CustomTag
            label={String(params.row.status).toLocaleUpperCase()}
            type="filled"
            color={
              String(params.row.status).toLocaleLowerCase() === "online"
                ? "green"
                : "red"
            }
          />
          {params.row.current_alarms_present === "yes" ? (
            <Warning style={{ marginTop: 7, marginLeft: 5 }} color="error" />
          ) : null}
        </div>
      ),
    },
  ];

  const respMaintainerAndCompanyColumns = [
    {
      headerName: `${t("name")}`,
      field: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link style={{ color: "black" }} to={nextLink(params.row.id)}>
          {params.row.name}
        </Link>
      ),
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_number ? params.row.system_number : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("systemSerialNumber")}`,
      field: "system_serial_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_serial_number
            ? params.row.system_serial_number
            : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("idGestionale")}`,
      field: "idGestionale",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.id_gestionale ? params.row.id_gestionale : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.installation_info?.address || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("installer")}`,
      field: "actual_installer_name",
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">{`${params.row.actual_installer_name} - ${params.row.actual_installer_email}`}</div>
      ),
    },
    {
      headerName: `${t("mantainer")}`,
      field: "manutentore_assigned_name",
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.manutentore_assigned_name &&
          (params.row?.manutentore_assigned_name || []).filter(
            (name: string) => name.trim() !== ""
          ).length > 0
            ? (params.row?.manutentore_assigned_name || [])
                .filter((name: string) => name.trim() !== "")
                .join(", ")
            : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("status")}`,
      field: "status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          <CustomTag
            label={String(params.row.status).toLocaleUpperCase()}
            type="filled"
            color={
              String(params.row.status).toLocaleLowerCase() === "online"
                ? "green"
                : "red"
            }
          />
          {params.row.current_alarms_present === "yes" ? (
            <div>
              {" "}
              <Warning style={{ marginTop: 7, marginLeft: 3 }} color="error" />
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const maintainerColumns = [
    {
      headerName: `${t("name")}`,
      field: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link style={{ color: "black" }} to={nextLink(params.row.id)}>
          {params.row.name}
        </Link>
      ),
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_number ? params.row.system_number : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("systemSerialNumber")}`,
      field: "system_serial_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_serial_number
            ? params.row.system_serial_number
            : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("idGestionale")}`,
      field: "idGestionale",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.id_gestionale ? params.row.id_gestionale : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.installation_info?.address || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("installer")}`,
      field: "actual_installer_name",
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">{`${params.row.actual_installer_name} - ${params.row.actual_installer_email}`}</div>
      ),
    },
    {
      headerName: `${t("status")}`,
      field: "status",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          <CustomTag
            label={String(params.row.status).toLocaleUpperCase()}
            type="filled"
            color={
              String(params.row.status).toLocaleLowerCase() === "online"
                ? "green"
                : "red"
            }
          />
          {params.row.current_alarms_present === "yes" ? (
            <div>
              {" "}
              <Warning style={{ marginTop: 7, marginLeft: 3 }} color="error" />
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const getColumnsByRole = (role: string) => {
    switch (role) {
      case roles.customer:
        return customerColumns;
      case roles.installer:
        return installerColumns;
      case roles.respMaintainer:
      case roles.company:
        return respMaintainerAndCompanyColumns;
      case roles.maintainer:
        return maintainerColumns;
      default:
        return customerColumns;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t(title)}
          goBack={() => navigate(companyInfo ? companyListToChartUrl : mainUrl)}
        />
      </Grid>
      {companyInfo && (
        <Grid item xs={12} md={8}>
          <CustomCard
            header={{ visible: true, title: `${t("companyRegistry")}` }}
            content={
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomList
                    listElement={[
                      {
                        primaryText: t("company"),
                        action: company.name,
                      },
                      {
                        primaryText: t("email"),
                        action: company.email,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomList
                    listElement={[
                      {
                        primaryText: t("totElevators"),
                        action: elevators?.length || "0",
                      },
                      {
                        primaryText: t("phone"),
                        action: company.phone,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomTable
          onRowClick={(row) => navigate(nextLink(row.id))}
          columns={getColumnsByRole(role)}
          rows={[...elevators]}
        />
      </Grid>
    </Grid>
  );
};

export default ElevatorListToChart;
