import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { chartPageUrl, mainUrl, roles } from "../costants";
import CustomTag from "../../custom/CustomTag";
import { Link, useNavigate } from "react-router-dom";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import { getAlarms, getElevators } from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";

type AlarmProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const Alarm: React.FC<AlarmProps> = ({ setBreadcrumb, role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link key="1" to={mainUrl} style={{ color: "#ffffff" }}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("alarmsHistory")}
      </Typography>,
    ]);
  }, []);

  // Funzione per formattare la data nel formato YYYY-MM-DD
  function formatDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [alarms, setAlarms] = useState<any[]>([]);
  const [values, setValues] = useState({
    start_date: formatDate(
      new Date(new Date().setDate(new Date().getDate() - 6))
    ),
    end_date: formatDate(new Date()),
  });
  const handleSubmit = () => {
    getAlarms(
      new Date(values.start_date).toISOString(),
      new Date(values.end_date).toISOString(),
      role
    ).then((res: any) => {
      if (res && res.alarms) {
        setAlarms(res.alarms);
      } else {
        setAlarms([]);
        enqueueSnackbar(t("noData"), { variant: "error" });
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const isSuperAdminColumns: () => GridColDef[] = () => {
    if (role === roles.superadmin) {
      return [
        {
          headerName: `${t("admin")}`,
          field: "admin",
          flex: 1,
        },
        {
          headerName: `${t("company")}`,
          field: "azienda_name",
          flex: 1,
        },
      ];
    } else {
      return [];
    }
  };

  const isAdminColumns: () => GridColDef[] = () => {
    if (role === roles.admin) {
      return [
        {
          headerName: `${t("company")}`,
          field: "azienda_name",
          flex: 1,
        },
      ];
    } else {
      return [];
    }
  };

  const [loadingElevators, setLoadingElevators] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    getElevators(role).then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoadingElevators(false);
    });
  }, []);

  if (loading || loadingElevators) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("selectInterval")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={8}>
        <CustomCard
          actions={[
            <CustomButton
              fullWidth
              type="outlined"
              label={t("reset")}
              onClick={() => {
                setValues({ start_date: "", end_date: "" });
              }}
              confirm
              confirmQuestion={`${t("question")}`}
            />,
            <CustomButton
              fullWidth
              type="contained"
              label={t("ok")}
              onClick={handleSubmit}
            />,
          ]}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    console.log(e.target.value);
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "2%" }}>
        <CustomTable
          columns={[
            ...isSuperAdminColumns(),
            ...isAdminColumns(),
            {
              headerName: `${t("installationName")}`,
              field: "installation_name",
              flex: 1,
            },
            {
              headerName: `${t("systemNumber")}`,
              field: "system_number",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => {
                const tmp = elevators.find(
                  (e) => e.id === params.row.installation_id
                );
                return (
                  <Link
                    to={chartPageUrl(params.row.installation_id)}
                    style={{ color: "black" }}
                  >
                    {tmp?.system_number}
                  </Link>
                );
              },
            },
            {
              headerName: `${t("type")}`,
              field: "tag",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => {
                if (params.row.tag === "p_max_motor") {
                  return `${t("maximumEnginePower")}`;
                }
                if (params.row.tag === "p_lim_standby") {
                  return `${t("standbyPowerLimit")}`;
                }
                if (params.row.tag === "rides_limit") {
                  return `${t("ridesLimit")}`;
                }
                if (params.row.tag === "offline") {
                  return `${t("deviceOffline")}`;
                }
                return (
                  <Tooltip title={t(params.row.tag)}>{params.row.tag}</Tooltip>
                );
              },
            },
            // {
            //   headerName: `${t("level")}`,
            //   field: "level",
            //   flex: 0.6,
            //   renderCell: (params: GridRenderCellParams) => (
            //     <CustomTag
            //       label={String(t(params.row.level)).toLocaleUpperCase()}
            //       type="filled"
            //       color={
            //         String(params.row.level).toLocaleLowerCase() === "critical"
            //           ? "red"
            //           : String(params.row.level).toLocaleLowerCase() ===
            //             "warning"
            //           ? "#F86F15"
            //           : "#FAC218"
            //       }
            //     />
            //   ),
            // },
            {
              headerName: `${t("start")}`,
              field: "start",
              flex: 1,
              filterable: false,
              renderCell: (params: GridRenderCellParams) => {
                return new Date(params.row.start).toLocaleString();
              },
            },
            {
              headerName: `${t("end")}`,
              field: "finish",
              flex: 1,
              filterable: false,
              renderCell: (params: GridRenderCellParams) => {
                if (params.row.finish) {
                  return new Date(params.row.finish).toLocaleString();
                } else {
                  return "N/A";
                }
              },
            },
          ]}
          rows={alarms}
        />
      </Grid>
    </Grid>
  );
};

export default Alarm;
