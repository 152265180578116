import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import {
  alarmsThresholdUrl,
  mainUrl,
  roles,
  wifiConfigurationUrl,
} from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete, Edit, EditNotifications, Wifi } from "@mui/icons-material";
import {
  deleteElevator,
  getElevators,
} from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";

type ElevatorsListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: string | ((id: string) => string);
  role: string;
};

const ElevatorsList: React.FC<ElevatorsListProps> = ({
  setBreadcrumb,
  link,
  role,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  document.body.style.backgroundColor = "#ffffff";

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {role === roles.company ? t("updateDeleteDevice") : t("updateDevice")}
      </Typography>,
    ]);
  }, []);

  const [reload, setReload] = useState<boolean>(false);
  useEffect(() => {
    getElevators(role).then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, [reload]);

  const [columns, setColumns] = React.useState<GridColDef[]>([
    {
      headerName: `${t("name")}`,
      field: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.name ? params.row.name : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_number ? params.row.system_number : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("systemSerialNumber")}`,
      field: "system_serial_number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.system_serial_number
            ? params.row.system_serial_number
            : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("idGestionale")}`,
      field: "idGestionale",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row?.id_gestionale ? params.row.id_gestionale : "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="rendercellclass">
          {params.row.installation_info?.address || "N/A"}
        </div>
      ),
    },
    {
      headerName: `${t("installationDate")}`,
      field: "created_at",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row?.created_at) return "N/A";
        return new Date(params.row.created_at).toLocaleString();
      },
    },
    {
      headerName: ``,
      field: "id",
      flex:
        role === roles.superadmin || role === roles.respMaintainer ? 1 : 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <CustomIconButton
            icon={<Edit />}
            type="outlined"
            onClick={() =>
              typeof link === "string"
                ? navigate(link)
                : navigate(link(params.row.id))
            }
            tooltip={`${t("edit")}`}
          />
          {(role === roles.respMaintainer ||
            role === roles.company ||
            role === roles.superadmin) && (
            <CustomIconButton
              type="outlined"
              icon={<Delete />}
              confirm
              tooltip={`${t("delete")}`}
              onClick={() =>
                deleteElevator(params.row.id, role).then((res) => {
                  if (res && !res.err) {
                    setReload((prev) => !prev);
                    enqueueSnackbar(t("elevatorDeletedSuccess"), {
                      variant: "success",
                    });
                  } else {
                    enqueueSnackbar(
                      t("elevatorDeletedError") + " " + res?.err?.message,
                      {
                        variant: "error",
                      }
                    );
                  }
                })
              }
            />
          )}
          {(role === roles.respMaintainer || role === roles.superadmin) && (
            <CustomIconButton
              type="outlined"
              icon={<Wifi />}
              onClick={() =>
                navigate(wifiConfigurationUrl + `?deviceId=${params.row.id}`)
              }
              tooltip={`Modifica rete WiFi`}
            />
          )}
          {role === roles.respMaintainer && (
            <CustomIconButton
              type="outlined"
              icon={<EditNotifications />}
              onClick={() => navigate(alarmsThresholdUrl(params.row.id))}
              tooltip={`Modifica soglie allarme`}
            />
          )}
        </div>
      ),
    },
  ]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={
            role === roles.company ? t("updateDeleteDevice") : t("updateDevice")
          }
          goBack={() => navigate("/" + mainUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable columns={columns} rows={[...elevators]} />
      </Grid>
    </Grid>
  );
};

export default ElevatorsList;
