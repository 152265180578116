import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { getAzMaintenance } from "../../../api/services/azService";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import CustomTable from "../../custom/CustomTable";
import emptyImg from "../../../images/empty.svg";
import CustomText from "../../custom/CustomText";
import CustomLoading from "../../custom/CustomLoading";
import { getElevators } from "../../../api/services/elevatorService";
import CustomSelect from "../../custom/CustomSelect";
import { getJobs } from "../../../api/services/configuratorService";
import CustomTag from "../../custom/CustomTag";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";

type AzUserMaintenanceProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const AzUserMaintenance: React.FC<AzUserMaintenanceProps> = ({
  setBreadcrumb,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("maintenanceHistory")}
      </Typography>,
    ]);
  }, []);

  const [loadingElevators, setLoadingElevators] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  useEffect(() => {
    getElevators(role).then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
        setValues({
          ...values,
          elevatorId: res.installations.map(
            (el: any) => `${el.id}$$$${el.id_gestionale}`
          ),
        });
        handleSearch(
          res.installations.map((el: any) => `${el.id}$$$${el.id_gestionale}`),
          res.installations
        );
      }
      setLoadingElevators(false);
    });
  }, []);

  const [empty, setEmpty] = useState<boolean>(false);
  const [loadMaintenance, setLoadMaintenance] = useState<boolean>(false);
  const [maintenances, setMaintenances] = useState<any[]>([]);
  const handleSearch = (elevator?: any, allElevators?: any[]) => {
    if (new Date(values.start_date) <= new Date(values.end_date)) {
      setLoadMaintenance(true);
      const tmpEl = elevator ? elevator : values.elevatorId;
      const jobPromises = tmpEl.map((id: string) =>
        getJobs(id.split("$$$")[0])
      );
      const azPromises = tmpEl.map((id: string) =>
        getAzMaintenance(id.split("$$$")[1])
      );
      Promise.all([...jobPromises, ...azPromises]).then((result) => {
        const jobResults = result.slice(0, jobPromises.length);
        const azResults = result.slice(jobPromises.length);
        let tmp: any[] = [];

        jobResults.forEach((rr, index) => {
          if (rr && rr.jobs) {
            rr.jobs.forEach((job: any) => {
              const tmpDate = job.scheduled_at
                ? job.scheduled_at
                : job.result_at
                ? job.result_at
                : "";
              if (
                new Date(tmpDate) >= new Date(values.start_date) &&
                new Date(tmpDate) <= new Date(values.end_date)
              ) {
                const selectedElevator: any = (
                  allElevators ? allElevators : elevators
                ).find((el) =>
                  (elevator ? elevator : values.elevatorId)[index]
                    .split("$$$")[0]
                    .includes(el.id)
                );
                if (
                  job.name === "change_speed" ||
                  job.name === "disable_batt_test" ||
                  job.name === "local_test" ||
                  job.name === "change_test_params"
                ) {
                  tmp.push({
                    installation_container_name:
                      selectedElevator?.installation_container_name || "N/A",
                    system_number: selectedElevator?.system_number || "N/A",
                    date: tmpDate,
                    type_description: job.name,
                    status: job.status,
                  });
                }
              }
            });
          }
        });
        azResults.forEach((res, ii) => {
          if (res && res.maintenance_history) {
            res.maintenance_history.forEach((el: any) => {
              if (
                new Date(el.date) >= new Date(values.start_date) &&
                new Date(el.date) <= new Date(values.end_date)
              ) {
                const selectedElevator: any = (
                  allElevators ? allElevators : elevators
                ).find((el) =>
                  (elevator ? elevator : values.elevatorId)[ii]
                    .split("$$$")[0]
                    .includes(el.id)
                );
                tmp.push({
                  installation_container_name:
                    selectedElevator.installation_container_name,
                  system_number: selectedElevator.system_number,
                  date: el?.date || "",
                  type_description: el?.type_description || "",
                  status: "done",
                });
              }
            });
          }
        });

        if (tmp.length > 0) {
          setMaintenances([...tmp]);
          setEmpty(false);
        } else {
          setMaintenances([]);
          setEmpty(true);
        }

        setLoadMaintenance(false);
      });
    } else {
      enqueueSnackbar(t("startEndDate"), { variant: "error" });
    }
  };

  function formatDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [values, setValues] = useState<any>({
    elevatorId: [],
    start_date: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    end_date: formatDate(new Date()),
  });

  const checkJobTitle = (job: string) => {
    switch (job) {
      case "change_speed":
        return "Modifica velocità";
      case "disable_batt_test":
        return "Modifica test periodico";
      case "local_test":
        return "Attivazione test immediato";
      case "change_test_params":
        return "Configurazione test periodico";
      default:
        return job;
    }
  };

  if (loadingElevators) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("maintenanceHistory")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomSelect
                  multiple
                  value={values.elevatorId}
                  label={`${t("selectElevator")}`}
                  handleChange={(e: any) =>
                    setValues({
                      ...values,
                      elevatorId: e.target.value,
                    })
                  }
                  options={elevators.map((el) => ({
                    value: `${el.id}$$$${el.id_gestionale}`,
                    label: `${el.name}`,
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton
                  label={t("reset")}
                  onClick={() => {
                    setValues({
                      elevatorId: [],
                      start_date: formatDate(
                        new Date(new Date().setDate(new Date().getDate() - 30))
                      ),
                      end_date: formatDate(new Date()),
                    });
                  }}
                  type="outlined"
                  confirm
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton
                  label={t("search")}
                  onClick={() => handleSearch(undefined, undefined)}
                  type="contained"
                  fullWidth
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      {loadMaintenance && (
        <Grid item xs={12}>
          <CustomLoading />
        </Grid>
      )}
      {empty && !loadMaintenance ? (
        <Grid item container xs={12}>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "80px" }}>
            <CustomText label={`${t("noData")}`} type="h5" />
          </Grid>
          <Grid item xs={12}>
            <img
              src={emptyImg}
              alt="logo"
              width="20%"
              style={{ marginLeft: "39%", marginRight: "39%" }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {maintenances.length > 0 && !loadMaintenance && (
            <CustomTable
              columns={[
                {
                  headerName: `${t("name")}`,
                  field: "installation_container_name",
                  flex: 1,
                },
                {
                  headerName: `${t("systemNumber")}`,
                  field: "system_number",
                  flex: 1,
                },
                {
                  headerName: `${t("date")} ${t("job")}`,
                  field: "date",
                  flex: 1,
                  renderCell: (params: any) => {
                    return new Date(params.row.date).toLocaleDateString();
                  },
                },
                {
                  headerName: `${t("description")}`,
                  field: "type_description",
                  flex: 1,
                  renderCell: (params: any) => {
                    return checkJobTitle(params.row.type_description);
                  },
                },
                {
                  headerName: `${t("status")}`,
                  field: "status",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) => (
                    <CustomTag
                      label={t(params.row.status)}
                      type="filled"
                      color={
                        params.row.status === "pending"
                          ? "orange"
                          : params.row.status === "done"
                          ? "green"
                          : "red"
                      }
                    />
                  ),
                },
              ]}
              rows={[...maintenances]}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default AzUserMaintenance;
