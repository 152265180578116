import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, roles } from "../costants";
import { deleteUser, getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import { checkColor } from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";

type UsersListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string | ((id: string) => string);
  types: (
    | "customers"
    | "manutentori"
    | "responsabili"
    | "installers"
    | "aziende"
    | "admins"
  )[];
  uid: string;
  actualRole: string;
};

const UsersList: React.FC<UsersListProps> = ({
  setBreadcrumb,
  title,
  link,
  types,
  uid,
  actualRole,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    const promises = types.map(async (type) => {
      const res = await getUsers(type);
      return res[type] || [];
    });
    Promise.all(promises).then((results) => {
      const tmp = results.reduce((acc, result) => [...acc, ...result], []);
      const unique = tmp
        .filter(
          (value: any, index: any, self: any) =>
            self.findIndex((v: any) => v.uid === value.uid) === index
        )
        .filter((user: any) => user.uid !== uid);
      setUsers(unique);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={
            actualRole === roles.superadmin ||
            actualRole === roles.admin ||
            actualRole === roles.company
              ? [
                  { headerName: `${t("nameSurname")}`, field: "name", flex: 1 },
                  {
                    headerName: `${t("email")}`,
                    field: "email",
                    flex: 1,
                  },
                  {
                    headerName: `${t("role")}`,
                    field: "roles",
                    flex: 1.3,
                    renderCell: (params: GridRenderCellParams) => (
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        style={{ paddingTop: "4px", paddingBottom: "4px" }}
                        className="rendercellclass"
                      >
                        {params.row.roles && params.row.roles.length > 0
                          ? params.row.roles.map(
                              (role: string, index: number) => (
                                <Grid item>
                                  <CustomTag
                                    key={index}
                                    label={
                                      role === "customer"
                                        ? t("customer")
                                        : role === "responsabile"
                                        ? t("mantainerResponsible")
                                        : role === "installer"
                                        ? t("installer")
                                        : role === "admin"
                                        ? t("admin")
                                        : role === "manutentore"
                                        ? t("mantainer")
                                        : role === "azienda"
                                        ? t("company")
                                        : role
                                    }
                                    type="filled"
                                    color={checkColor(role)}
                                  />
                                </Grid>
                              )
                            )
                          : "N/A"}
                      </Grid>
                    ),
                  },
                  {
                    headerName: `${t("onlyAddress")}`,
                    field: "user_info.address",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <div className="rendercellclass">
                        {params.row.user_info?.address || "N/A"}
                      </div>
                    ),
                  },
                  {
                    headerName: `${t("phone")}`,
                    field: "user_info.phone",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <div className="rendercellclass">
                        {params?.row?.user_info?.phone || "N/A"}
                      </div>
                    ),
                  },
                  {
                    headerName: `Azienda di riferimento`,
                    field: "company_name",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <div className="rendercellclass">
                        {params.row?.company_name || "N/A"}
                      </div>
                    ),
                  },
                  {
                    headerName: ``,
                    field: "uid",
                    flex: 0.5,
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        <CustomIconButton
                          tooltip={`${t("edit")}`}
                          icon={<Edit />}
                          type="outlined"
                          onClick={() =>
                            typeof link === "string"
                              ? navigate(link)
                              : navigate(link(params.row.uid))
                          }
                        />
                        <CustomIconButton
                          tooltip={`${t("delete")}`}
                          confirm
                          icon={<Delete />}
                          type="outlined"
                          onClick={() => {
                            deleteUser(params.row.uid).then((res: any) => {
                              if (res && !res.err) {
                                enqueueSnackbar(t("userDeletedSuccess"), {
                                  variant: "success",
                                });
                                window.location.reload();
                              } else {
                                enqueueSnackbar(
                                  `${t("userDeletedError")} ${
                                    res?.err?.message
                                  }`,
                                  {
                                    variant: "error",
                                  }
                                );
                              }
                            });
                          }}
                        />
                      </>
                    ),
                  },
                ]
              : [
                  {
                    headerName: `${t("email")}`,
                    field: "email",
                    flex: 1,
                  },
                  { headerName: `${t("nameSurname")}`, field: "name", flex: 1 },
                  {
                    headerName: `${t("role")}`,
                    field: "roles",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        className="rendercellclass"
                      >
                        {params.row.roles && params.row.roles.length > 0
                          ? params.row.roles.map(
                              (role: string, index: number) => (
                                <Grid item>
                                  <CustomTag
                                    key={index}
                                    label={
                                      role === "customer"
                                        ? t("customer")
                                        : role === "responsabile"
                                        ? t("mantainerResponsible")
                                        : role === "installer"
                                        ? t("installer")
                                        : role === "admin"
                                        ? t("admin")
                                        : role === "manutentore"
                                        ? t("mantainer")
                                        : role === "azienda"
                                        ? t("company")
                                        : role
                                    }
                                    type="filled"
                                    color={checkColor(role)}
                                  />
                                </Grid>
                              )
                            )
                          : "N/A"}
                      </Grid>
                    ),
                  },
                  {
                    headerName: `${t("onlyAddress")}`,
                    field: "user_info.address",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <div className="rendercellclass">
                        {params.row.user_info?.address || "N/A"}
                      </div>
                    ),
                  },
                  {
                    headerName: `${t("phone")}`,
                    field: "user_info.phone",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <div className="rendercellclass">
                        {params?.row?.user_info?.phone || "N/A"}
                      </div>
                    ),
                  },
                  {
                    headerName: ``,
                    field: "uid",
                    flex: 0.5,
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        <CustomIconButton
                          tooltip={`${t("edit")}`}
                          icon={<Edit />}
                          type="outlined"
                          onClick={() =>
                            typeof link === "string"
                              ? navigate(link)
                              : navigate(link(params.row.uid))
                          }
                        />
                        <CustomIconButton
                          tooltip={`${t("delete")}`}
                          confirm
                          icon={<Delete />}
                          type="outlined"
                          onClick={() => {
                            deleteUser(params.row.uid).then((res: any) => {
                              if (res && !res.err) {
                                enqueueSnackbar(t("userDeletedSuccess"), {
                                  variant: "success",
                                });
                                window.location.reload();
                              } else {
                                enqueueSnackbar(
                                  `${t("userDeletedError")} ${
                                    res?.err?.message
                                  }`,
                                  {
                                    variant: "error",
                                  }
                                );
                              }
                            });
                          }}
                        />
                      </>
                    ),
                  },
                ]
          }
          rows={users}
        />
      </Grid>
    </Grid>
  );
};

export default UsersList;
