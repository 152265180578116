import { isDev } from "../../components/pages/costants";
import { CreateNewDeviceRequest } from "../requests/configuratorService";
import {
  ZDMFetch,
  apiKey,
  binaryFetch,
  firmwareId,
  methodGet,
  methodPost,
  methodPut,
  workspaceId,
} from "./httpRequests";
import {
  activeSimUrl,
  checkZfsStatusUrl,
  claimDeviceUrl,
  commitUrl,
  createNewDeviceUrl,
  getDeviceUrl,
  getFirmwareVersionsUrl,
  getIdentityFromDCNUrl,
  getJobStatusUrl,
  getJobsUrl,
  getLastDUrl,
  getZfsFileUrl,
  getZfsUrl,
  initUrl,
  mklfsUrl,
  uploadFilesUrl,
} from "./url";

export const createNewDevice = (req: CreateNewDeviceRequest) => {
  return ZDMFetch(
    methodPost,
    createNewDeviceUrl(workspaceId),
    JSON.stringify(req)
  );
};

export const getIdentityFromDCN = (dcn: string) => {
  return ZDMFetch(methodGet, getIdentityFromDCNUrl(dcn));
};

export const claimDevice = (deviceId: string, bundle: string) => {
  return ZDMFetch(
    methodPost,
    claimDeviceUrl(workspaceId, deviceId),
    JSON.stringify({ bundle: bundle })
  );
};

export const ActiveSim = (deviceId: string) => {
  return ZDMFetch(
    methodPut,
    activeSimUrl(workspaceId, deviceId),
    JSON.stringify({ status: "active" })
  );
};

export const mklfs = (files: { files: { [path: string]: string } }) => {
  return binaryFetch(methodPost, mklfsUrl, JSON.stringify(files));
};

export const init = (deviceId: string) => {
  return ZDMFetch(methodPost, initUrl(workspaceId, deviceId));
};

export const uploadFiles = (deviceId: string, files: { file: File }[]) => {
  const formdata = new FormData();
  files.map((f) => formdata.append(f.file.name, f.file));
  return fetch(uploadFilesUrl(workspaceId, deviceId), {
    method: methodPost,
    body: formdata,
    headers: {
      Accept: "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const commit = (deviceId: string, schedule: boolean) => {
  return ZDMFetch(methodPost, commitUrl(workspaceId, deviceId, schedule));
};

export const getFirmwareVersions = () => {
  return ZDMFetch(methodGet, getFirmwareVersionsUrl(workspaceId, firmwareId));
};

export const getDevice = (deviceId: string) => {
  return ZDMFetch(methodGet, getDeviceUrl(workspaceId, deviceId));
};

export const getZfs = (deviceId: string) => {
  return ZDMFetch(methodGet, getZfsUrl(workspaceId, deviceId));
};

export const getZfsFile = (deviceId: string, zfsId: string) => {
  return ZDMFetch(methodGet, getZfsFileUrl(workspaceId, deviceId, zfsId));
};

export const getJobs = (deviceId: string) => {
  return ZDMFetch(methodGet, getJobsUrl(deviceId));
};

export const checkZfsStatus = (deviceId: string) => {
  return ZDMFetch(methodGet, checkZfsStatusUrl(deviceId));
};

export const getJobStatus = (deviceId: string) => {
  if(isDev){
    return new Promise((resolve) => {
      resolve({
        "events": [
            {
                "ts": "2025-03-17T13:55:32.836Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:55:32.067Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1742219732067
                }
            },
            {
                "ts": "2025-03-17T13:35:40.735Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:35:40.457Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:35:39.675Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1742218539675
                }
            },
            {
                "ts": "2025-03-17T13:33:27.715Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:33:27.436Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:33:25.554Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1742218405554
                }
            },
            {
                "ts": "2025-03-17T13:31:41.034Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 35
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:31:39.857Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 35
                        }
                    },
                    "version": 1742218299857
                }
            },
            {
                "ts": "2025-03-17T13:30:11.775Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:30:09.693Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 0
                        }
                    },
                    "version": 1742218209693
                }
            },
            {
                "ts": "2025-03-17T13:28:06.716Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 35
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T13:27:48.629Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 35
                        }
                    },
                    "version": 1742218068629
                }
            },
            {
                "ts": "2025-03-17T12:00:42.471Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T12:00:41.773Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T12:00:40.697Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1742212840697
                }
            },
            {
                "ts": "2025-03-17T11:59:16.334Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T11:59:15.946Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T11:59:15.045Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1742212755045
                }
            },
            {
                "ts": "2025-03-17T11:37:13.912Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 60
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T11:37:13.633Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T11:37:13.04Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 60
                        }
                    },
                    "version": 1742211433040
                }
            },
            {
                "ts": "2025-03-17T11:34:24.914Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 60
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-17T11:34:24.358Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 60
                        }
                    },
                    "version": 1742211264358
                }
            },
            {
                "ts": "2025-03-14T14:22:39.188Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T14:22:37.8Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1741962157800
                }
            },
            {
                "ts": "2025-03-14T14:18:43.191Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 0
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T14:18:41.527Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 0
                        }
                    },
                    "version": 1741961921527
                }
            },
            {
                "ts": "2025-03-14T14:18:29.932Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 0
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T14:18:29.93Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T14:18:27.707Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 0
                        }
                    },
                    "version": 1741961907707
                }
            },
            {
                "ts": "2025-03-14T13:58:27.407Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T13:58:25.831Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1741960705831
                }
            },
            {
                "ts": "2025-03-14T13:47:19.592Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T13:47:18.149Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 0
                        }
                    },
                    "version": 1741960038149
                }
            },
            {
                "ts": "2025-03-14T11:47:32.3Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T11:47:30.336Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1741952850336
                }
            },
            {
                "ts": "2025-03-14T11:05:09.178Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T11:05:09.177Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T11:05:07.68Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1741950307680
                }
            },
            {
                "ts": "2025-03-14T10:42:10.015Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T10:42:10.014Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T10:42:08.138Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1741948928138
                }
            },
            {
                "ts": "2025-03-14T10:30:53.802Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@clear_tslog",
                    "value": {
                        "info": "deleting tslog",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-14T10:30:52.962Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@clear_tslog",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-03-03T15:01:40.517Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@clear_tslog",
                    "value": {
                        "args": {}
                    },
                    "version": 1741014100517
                }
            },
            {
                "ts": "2025-02-14T07:22:33.058Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-14T07:22:31.563Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1739517751563
                }
            },
            {
                "ts": "2025-02-14T07:20:56.081Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-14T07:20:56.08Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-14T07:20:54.802Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1739517654802
                }
            },
            {
                "ts": "2025-02-13T13:58:17.721Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-13T13:58:17.72Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-13T13:58:16.164Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1739455096164
                }
            },
            {
                "ts": "2025-02-13T13:58:01.599Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 0
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-13T13:58:01.1Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-13T13:57:59.493Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 0
                        }
                    },
                    "version": 1739455079493
                }
            },
            {
                "ts": "2025-02-07T14:57:01.501Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-02-07T14:56:59.82Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1738940219820
                }
            },
            {
                "ts": "2025-01-24T10:15:04.299Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T10:15:02.136Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1737713702136
                }
            },
            {
                "ts": "2025-01-24T10:13:26.524Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T10:13:24.792Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1737713604792
                }
            },
            {
                "ts": "2025-01-24T10:07:06.3Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T10:07:06.001Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T10:07:05.103Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737713225103
                }
            },
            {
                "ts": "2025-01-24T10:06:29.382Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T10:06:29.381Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T10:06:16.808Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737713176808
                }
            },
            {
                "ts": "2025-01-24T09:44:42.491Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:44:40.488Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737711880488
                }
            },
            {
                "ts": "2025-01-24T09:41:30.737Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@get_cfg",
                    "value": {
                        "config": {
                            "exps": [],
                            "params": {
                                "wtd": 60000,
                                "dn_wtd": 120000,
                                "acq_freq": 500,
                                "pub_freq": 1000,
                                "tlog_thr": 2,
                                "fast_freq": 0,
                                "exc_period": 300,
                                "tlog_bsize": 512,
                                "tlog_rsize": 1600,
                                "info_period": 300,
                                "offline_thr": 20,
                                "resolve_thr": 5,
                                "store_period": 60,
                                "agent_timeout": 1500,
                                "dn_chunk_size": 32768
                            }
                        },
                        "params": {
                            "CA1": {
                                "v_eff": 230,
                                "offset": 0,
                                "sensor": "CH5",
                                "amperage": 150,
                                "buff_len": 1,
                                "w_thresh": 90000,
                                "clamp_type": "05V",
                                "three_phase": false,
                                "rolling_mode": "avg"
                            },
                            "CA2": {
                                "v_eff": 230,
                                "offset": 0,
                                "sensor": "CH6",
                                "amperage": 150,
                                "buff_len": 1,
                                "w_thresh": 90000,
                                "clamp_type": "05V",
                                "three_phase": false,
                                "rolling_mode": "avg"
                            },
                            "th1": 50,
                            "th2": 5,
                            "speed": 1,
                            "c_open": {
                                "type": "NO",
                                "sensor": "CH2",
                                "buff_len": 1,
                                "init_value": -1,
                                "digital_type": "res",
                                "rolling_mode": "max"
                            },
                            "c_close": {
                                "type": "NO",
                                "sensor": "CH3",
                                "buff_len": 1,
                                "init_value": -1,
                                "digital_type": "res",
                                "rolling_mode": "max"
                            },
                            "c_manovra": {
                                "type": "NO",
                                "sensor": "CH1",
                                "buff_len": 1,
                                "init_value": -1,
                                "digital_type": "res",
                                "rolling_mode": "max"
                            }
                        },
                        "sensors": {
                            "CH1": {
                                "set": {
                                    "PGA": 2,
                                    "SPS": 0,
                                    "exp": null,
                                    "type": "resistive",
                                    "input_number": 1
                                },
                                "conversion": {
                                    "args": {
                                        "name": "CH1",
                                        "delta": 5,
                                        "v_min": -50,
                                        "offset": 0,
                                        "ref_table": [
                                            0,
                                            1
                                        ],
                                        "out_of_range": 0
                                    },
                                    "type": "lookup_table"
                                }
                            },
                            "CH2": {
                                "set": {
                                    "PGA": 2,
                                    "SPS": 0,
                                    "exp": null,
                                    "type": "resistive",
                                    "input_number": 2
                                },
                                "conversion": {
                                    "args": {
                                        "name": "CH2",
                                        "delta": 5,
                                        "v_min": -50,
                                        "offset": 0,
                                        "ref_table": [
                                            0,
                                            1
                                        ],
                                        "out_of_range": 0
                                    },
                                    "type": "lookup_table"
                                }
                            },
                            "CH3": {
                                "set": {
                                    "PGA": 2,
                                    "SPS": 0,
                                    "exp": null,
                                    "type": "resistive",
                                    "input_number": 3
                                },
                                "conversion": {
                                    "args": {
                                        "name": "CH3",
                                        "delta": 5,
                                        "v_min": -50,
                                        "offset": 0,
                                        "ref_table": [
                                            0,
                                            1
                                        ],
                                        "out_of_range": 0
                                    },
                                    "type": "lookup_table"
                                }
                            },
                            "CH4": {
                                "set": {
                                    "PGA": 2,
                                    "SPS": 3300,
                                    "exp": null,
                                    "type": "voltage",
                                    "input_number": 4
                                },
                                "conversion": {
                                    "args": {
                                        "name": "CH4",
                                        "x_max": 10.2000,
                                        "x_min": 0,
                                        "y_max": 12,
                                        "y_min": 0,
                                        "offset": 0,
                                        "over_x": 12,
                                        "under_x": 0
                                    },
                                    "type": "linear"
                                }
                            },
                            "CH5": {
                                "set": {
                                    "PGA": 2,
                                    "SPS": 3300,
                                    "exp": null,
                                    "type": "voltage",
                                    "input_number": 5
                                },
                                "conversion": {
                                    "args": {
                                        "name": "CH5",
                                        "x_max": 10,
                                        "x_min": 0,
                                        "y_max": 10,
                                        "y_min": 0,
                                        "offset": 0,
                                        "over_x": 10,
                                        "under_x": 0
                                    },
                                    "type": "linear"
                                }
                            },
                            "CH6": {
                                "set": {
                                    "PGA": 2,
                                    "SPS": 3300,
                                    "exp": null,
                                    "type": "voltage",
                                    "input_number": 6
                                },
                                "conversion": {
                                    "args": {
                                        "name": "CH6",
                                        "x_max": 10,
                                        "x_min": 0,
                                        "y_max": 10,
                                        "y_min": 0,
                                        "offset": 0,
                                        "over_x": 10,
                                        "under_x": 0
                                    },
                                    "type": "linear"
                                }
                            }
                        }
                    }
                }
            },
            {
                "ts": "2025-01-24T09:41:28.978Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@get_cfg",
                    "value": {
                        "args": {}
                    },
                    "version": 1737711688978
                }
            },
            {
                "ts": "2025-01-24T09:36:18.568Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:36:17.133Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:36:15.37Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:35:46.383Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737711346383
                }
            },
            {
                "ts": "2025-01-24T09:27:52.115Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:27:51.835Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:27:50.462Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737710870462
                }
            },
            {
                "ts": "2025-01-24T09:25:55.134Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-24T09:25:53.542Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737710753542
                }
            },
            {
                "ts": "2025-01-23T09:31:21.252Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-23T09:31:21.251Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-23T09:31:18.965Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737624678965
                }
            },
            {
                "ts": "2025-01-23T09:24:42.791Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-23T09:24:41.176Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737624281176
                }
            },
            {
                "ts": "2025-01-23T09:23:15.511Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-23T09:23:14.891Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-23T09:23:13.441Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1737624193441
                }
            },
            {
                "ts": "2025-01-14T15:50:28.148Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:50:28.147Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:50:25.615Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1736869825615
                }
            },
            {
                "ts": "2025-01-14T15:17:23.381Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:17:22.945Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:17:20.925Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1736867840925
                }
            },
            {
                "ts": "2025-01-14T15:08:18.279Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@disable_batt_test",
                    "value": {
                        "info": {
                            "disable": false
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:08:17.863Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@change_test_params",
                    "value": {
                        "info": "wrong params",
                        "result": "failed"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:08:17.862Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@disable_batt_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-14T15:08:16.59Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@change_test_params",
                    "value": {
                        "args": {
                            "hour": 0,
                            "duration": 0,
                            "week_day": "",
                            "frequency": ""
                        }
                    },
                    "version": 1736867296590
                }
            },
            {
                "ts": "2025-01-14T15:08:16.582Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@disable_batt_test",
                    "value": {
                        "args": {
                            "batt_test": 0
                        }
                    },
                    "version": 1736867296582
                }
            },
            {
                "ts": "2025-01-09T15:18:25.432Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-09T15:18:25.431Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-09T15:18:22.632Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1736435902632
                }
            },
            {
                "ts": "2025-01-09T15:16:58.233Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-09T15:16:57.961Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-09T15:16:55.79Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1736435815790
                }
            },
            {
                "ts": "2025-01-09T15:15:47.374Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-09T15:15:45.397Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1736435745397
                }
            },
            {
                "ts": "2025-01-09T15:03:01.263Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2025-01-09T15:03:00.518Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1736434980518
                }
            },
            {
                "ts": "2024-12-19T14:39:19.824Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 19
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:39:19.823Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:39:13.286Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 19
                        }
                    },
                    "version": 1734619153286
                }
            },
            {
                "ts": "2024-12-19T14:38:38.879Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:38:36.973Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 19
                        }
                    },
                    "version": 1734619116973
                }
            },
            {
                "ts": "2024-12-19T14:29:33.384Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 19
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:29:32.977Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:29:32.29Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 19
                        }
                    },
                    "version": 1734618572290
                }
            },
            {
                "ts": "2024-12-19T14:28:30.857Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 19
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:28:30.175Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-19T14:27:34.172Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 19
                        }
                    },
                    "version": 1734618454172
                }
            },
            {
                "ts": "2024-12-11T14:11:08.74Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T14:11:07.253Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1733926267253
                }
            },
            {
                "ts": "2024-12-11T14:11:02.961Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@change_test_params",
                    "value": {
                        "info": {
                            "hour": 12,
                            "duration": 20,
                            "week_day": "ven",
                            "frequency": "week"
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T14:11:02.4Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@disable_batt_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T14:11:01.722Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@change_test_params",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T14:10:59.73Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@change_test_params",
                    "value": {
                        "args": {
                            "hour": 12,
                            "duration": 20,
                            "week_day": "ven",
                            "frequency": "week"
                        }
                    },
                    "version": 1733926259730
                }
            },
            {
                "ts": "2024-12-11T14:10:59.613Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@disable_batt_test",
                    "value": {
                        "args": {
                            "batt_test": 0
                        }
                    },
                    "version": 1733926259613
                }
            },
            {
                "ts": "2024-12-11T08:51:01.338Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:51:01.337Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:50:59.672Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1733907059672
                }
            },
            {
                "ts": "2024-12-11T08:28:23.878Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:28:23.583Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:28:22.209Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1733905702209
                }
            },
            {
                "ts": "2024-12-11T08:25:50.077Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 120
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:25:49.358Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:25:47.273Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 120
                        }
                    },
                    "version": 1733905547273
                }
            },
            {
                "ts": "2024-12-11T08:23:44.46Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:23:42.819Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1733905422819
                }
            },
            {
                "ts": "2024-12-11T08:22:33.4Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:22:33.104Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T08:22:32.31Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 30
                        }
                    },
                    "version": 1733905352310
                }
            },
            {
                "ts": "2024-12-11T07:50:18.862Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T07:50:16.5Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1733903416500
                }
            },
            {
                "ts": "2024-12-11T07:49:22.215Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 20
                        },
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T07:49:21.937Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": "job completed",
                        "result": "done"
                    }
                }
            },
            {
                "ts": "2024-12-11T07:49:20.585Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "c2d",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "args": {
                            "duration": 20
                        }
                    },
                    "version": 1733903360585
                }
            },
            {
                "ts": "2024-12-11T07:43:52.68Z",
                "workspace_id": "wks-8oglsckydf5t",
                "fleet_id": "flt-8oglscl5v476",
                "device_id": "dev-9hf0u93rgk75",
                "type": "job",
                "direction": "d2c",
                "payload": {
                    "key": "@local_test",
                    "value": {
                        "info": {
                            "duration": 30
                        },
                        "result": "done"
                    }
                }
            }
        ]
    })
    });
  }
  return ZDMFetch(methodGet, getJobStatusUrl(workspaceId, deviceId));
};

export const getLastD = (deviceId: string) => {
  //https://api.cloud.zerynth.com/v3/timeseries/wks-8oglsckydf5t/data?size=1&from=0&device=dev-95imnumcju34&tag=b
  return ZDMFetch(methodGet, getLastDUrl(workspaceId, deviceId));
};
