import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { customLocaleText, lightColor } from "./utils";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

type CustomTableProps = {
  columns: GridColDef[];
  rows: any[];
  dense?: boolean;
  onRowClick?: (row: any) => void;
  checkbox?: {
    visible: boolean;
    selectedRows: any[];
    setSelectedRows: (rows: any[]) => void;
  };
};

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  rows,
  dense,
  onRowClick,
  checkbox,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isZoomed, setIsZoomed] = useState(window.devicePixelRatio > 1);

  useEffect(() => {
    const handleZoom = () => {
      setIsZoomed(window.devicePixelRatio > 1);
    };

    window.addEventListener("resize", handleZoom);
    window.addEventListener("scroll", handleZoom);

    return () => {
      window.removeEventListener("resize", handleZoom);
      window.removeEventListener("scroll", handleZoom);
    };
  }, []);

  const applyMobileStyles = isMobile || isZoomed;

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto", // Abilita lo scroll orizzontale
      }}
    >
      <DataGrid
        showCellVerticalBorder
        showColumnVerticalBorder
        scrollbarSize={0}
        getRowHeight={() => "auto"}
        sx={{
          minWidth: applyMobileStyles ? "max-content" : undefined,
          ".MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center",
          },
          ".MuiDataGrid-cell--textLeft": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: "#ffffff",
          },
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: lightColor,
            border: "none",
            //minWidth: applyMobileStyles ? "250px !important" : undefined,
            //maxWidth: applyMobileStyles ? "250px !important" : undefined,
          },
          ".MuiDataGrid-columnHeader--withRightBorder": {
            backgroundColor: lightColor,
            border: "none",
            minWidth: applyMobileStyles ? "250px !important" : undefined,
            maxWidth: applyMobileStyles ? "250px !important" : undefined,
          },
          ".MuiDataGrid-row": {
            cursor: onRowClick ? "pointer" : undefined,
          },
          ".MuiDataGrid-overlayWrapper": {
            minHeight: "50px !important",
          },
          ".MuiDataGrid-cell": {
            padding: "0px 8px",
            minWidth: applyMobileStyles ? "250px !important" : undefined,
            maxWidth: applyMobileStyles ? "250px !important" : undefined,
            minHeight: "50px !important",
            maxHeight: "none",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "break-spaces",
          },
        }}
        getRowId={(row) => rows.indexOf(row)}
        density={dense ? "compact" : "standard"}
        rows={rows}
        columns={columns}
        checkboxSelection={checkbox && checkbox.visible ? true : false}
        rowSelection={checkbox && checkbox.visible ? true : false}
        onRowSelectionModelChange={
          checkbox && checkbox.visible
            ? (newSelection) => checkbox.setSelectedRows(newSelection)
            : undefined
        }
        onRowClick={onRowClick ? (params) => onRowClick(params.row) : undefined}
        localeText={customLocaleText(t)}
        isCellEditable={() => false}
        slots={{
          noRowsOverlay: () => (
            <div style={{ textAlign: "center", paddingTop: "12px" }}>
              {t("noData")}
            </div>
          ),
        }}
      />
    </Box>
  );
};

export default CustomTable;
