import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { getElevators } from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";

type ElevatorHistoryProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const ElevatorHistory: React.FC<ElevatorHistoryProps> = ({
  setBreadcrumb,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("installations")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    getElevators(role).then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("installations")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("name")}`,
              field: "installation_container_name",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <div className="rendercellclass">
                  {params.row?.installation_container_name || "N/A"}
                </div>
              ),
            },
            {
              headerName: `${t("systemNumber")}`,
              field: "system_number",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <div className="rendercellclass">
                  {params.row?.system_number || "N/A"}
                </div>
              ),
            },
            {
              headerName: `${t("systemSerialNumber")}`,
              field: "system_serial_number",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <div className="rendercellclass">
                  {params.row?.system_serial_number || "N/A"}
                </div>
              ),
            },
            {
              headerName: `${t("address")}`,
              field: "installation_info",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <div className="rendercellclass">
                  {params.row.installation_info?.address || "N/A"}
                </div>
              ),
            },
            {
              headerName: `${t("installer")}`,
              field: "created_by_name",
              flex: 1,
            },
            {
              headerName: `${t("mantainer")}`,
              field: "manutentore_assigned_name",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <div className="rendercellclass">
                  {(params?.row?.manutentore_assigned_name || []).join(",") ||
                    "N/A"}
                </div>
              ),
            },
            {
              headerName: `${t("installationDate")}`,
              field: "created_at",
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                new Date(params.row.created_at).toLocaleDateString(),
            },
          ]}
          rows={[...elevators]}
        />
      </Grid>
    </Grid>
  );
};

export default ElevatorHistory;
