import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mainUrl } from "../costants";
import {
  getUser,
  getUsers,
  updateUser,
} from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import CustomText from "../../custom/CustomText";
import CustomTag from "../../custom/CustomTag";
import { greyColor } from "../../custom/utils";
import CustomSelect from "../../custom/CustomSelect";
import { availableRoles } from "../createUser/utils";
import { roles as allRoles } from "../costants";
import {
  getCompanyElevators,
  getElevators,
} from "../../../api/services/elevatorService";

type UpdateUserProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string;
  role: string;
  uid: string;
};

const UpdateUser: React.FC<UpdateUserProps> = ({
  setBreadcrumb,
  title,
  link,
  role,
  uid,
}) => {
  const { id } = useParams(); //user uid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [values, setValues] = useState<any>({
    email: "",
    name: "",
    address: "",
    phone: "",
    role: [],
    createdBy: role === allRoles.respMaintainer ? uid : "",
    responsible: false,
  });

  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [allAdmin, setAllAdmin] = useState<any[]>([]);
  const [allResponsibles, setAllResponsibles] = useState<any[]>([]);
  useEffect(() => {
    if (
      role === allRoles.superadmin ||
      role === allRoles.admin ||
      role === allRoles.company
    ) {
      getUsers("responsabili").then((res) => {
        if (res && res.responsabili) {
          setAllResponsibles(res.responsabili);
        }
      });
    }
    if (role === allRoles.superadmin || role === allRoles.admin) {
      getUsers("aziende").then((res) => {
        if (res && res.aziende) {
          setAllCompanies(res.aziende);
        }
      });
    }
    if (role === allRoles.superadmin) {
      getUsers("admins").then((res) => {
        if (res && res.admins) {
          setAllAdmin(res.admins);
        }
      });
    }
  }, [role]);

  useEffect(() => {
    if (values && values.role) {
      setBreadcrumb([
        <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
          {t("home")}
        </Link>,
        <Link style={{ color: "#ffffff" }} key="2" to={link}>
          {t(title)}
        </Link>,
        <Typography color="#ffffff" key="3">
          {values.name}
        </Typography>,
      ]);
    }
  }, [values]);

  useEffect(() => {
    getUser(id!).then((res: any) => {
      if (res && res.user && res.user?.roles && res.user?.roles.length > 0) {
        setValues({
          email: res.user?.email,
          name: res.user?.name,
          address: res.user?.user_info?.address,
          phone: res.user?.user_info?.phone,
          role: res.user?.roles,
          createdBy: res.user?.created_by,
          responsible:
            allResponsibles.filter((r) => r.uid === res.user?.created_by)
              .length > 0
              ? true
              : false,
        });
        setLifts((res.user?.installations || []).map((lift: any) => lift.id));
      }
      setLoading(false);
    });
  }, [allResponsibles]);

  const [availableLifts, setAvailableLifts] = useState<any[]>([]);
  const [lifts, setLifts] = useState<any[]>([]);
  useEffect(() => {
    if (role === allRoles.respMaintainer) {
      getElevators(allRoles.respMaintainer).then((res) => {
        if (res && res.installations) {
          setAvailableLifts(res.installations);
        } else {
          setAvailableLifts([]);
        }
      });
    } else {
      if (values.createdBy) {
        let tmp = values.createdBy;
        if (values.responsible) {
          const responsible = allResponsibles.filter(
            (r) => r.uid === values.createdBy
          );
          if (responsible.length > 0) {
            tmp = responsible[0].created_by;
          }
        }
        getCompanyElevators(tmp).then((res) => {
          //getElevators(role).then((res) => {
          if (res && res.installations) {
            setAvailableLifts(res.installations);
          } else {
            setAvailableLifts([]);
          }
        });
      } else {
        setAvailableLifts([]);
      }
    }
  }, [values.createdBy, values.responsible, allResponsibles]);

  const handleSubmit = () => {
    setDisableButton(true);
    if (values.role.length === 0) {
      enqueueSnackbar(t("roleError"), { variant: "error" });
      setDisableButton(false);
    } else {
      let tmpRole = values.role;
      if (
        values.role.includes(allRoles.respMaintainer) &&
        values.role.includes(allRoles.maintainer)
      ) {
        tmpRole = values.role.filter((r: string) => r !== allRoles.maintainer);
      }
      updateUser(id!, {
        email: values.email,
        name: values.name,
        role: tmpRole,
        user_info: {
          phone: values.phone,
          address: values.address,
          city: "",
          province: "",
          zip_code: "",
        },
        user_id: id!,
        created_by:
          values.role.includes(allRoles.company) &&
          !values.role.includes(allRoles.admin) &&
          role === allRoles.superadmin
            ? values.createdBy
            : (values.role.includes(allRoles.customer) ||
                values.role.includes(allRoles.installer) ||
                values.role.includes(allRoles.respMaintainer)) &&
              !values.role.includes(allRoles.company) &&
              (role === allRoles.superadmin || role === allRoles.admin)
            ? values.createdBy
            : values.role.includes(allRoles.maintainer) &&
              !values.role.includes(allRoles.respMaintainer) &&
              (role === allRoles.superadmin ||
                role === allRoles.admin ||
                role === allRoles.company)
            ? values.createdBy
            : id!,
        installations: lifts,
      }).then((res) => {
        if (res && res.result) {
          enqueueSnackbar(
            `${t("userCreatedSuccess")}. ${
              values.role.includes(allRoles.respMaintainer) &&
              values.role.includes(allRoles.maintainer)
                ? t("maintainerAndRespMaintainer")
                : ""
            }`,
            { variant: "success" }
          );
          navigate(-1);
        } else {
          enqueueSnackbar(`${t("updateUserError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
        setDisableButton(false);
      });
    }
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t(title)}: ${values.name}`}
          goBack={() => navigate(-1)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} alignItems="center">
              <Grid item container xs={12} justifyContent="space-between">
                <Grid item>
                  <CustomText label={`${t("email")}`} type="subtitle1" />
                </Grid>
                <Grid item>
                  <CustomTag
                    label={values.email}
                    type="filled"
                    color={greyColor}
                  />
                </Grid>
              </Grid>
              {role === allRoles.respMaintainer && (
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid item>
                    <CustomText label={`${t("role")}`} type="subtitle1" />
                  </Grid>
                  <Grid item>
                    <CustomTag
                      label={(values.role || []).join(", ")}
                      type="filled"
                      color={greyColor}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                {role !== allRoles.respMaintainer && (
                  <CustomSelect
                    handleChange={(e) => {
                      if (e && e.target && e.target.value) {
                        setValues({
                          ...values,
                          role: [...e.target.value],
                        });
                        if (
                          (e.target.value.includes(allRoles.respMaintainer) ||
                            e.target.value.includes(allRoles.maintainer) ||
                            e.target.value.includes(allRoles.customer)) &&
                          e.target.value.includes(allRoles.company)
                        ) {
                          setLifts([]);
                        }
                      }
                    }}
                    value={values.role}
                    multiple
                    label={`${t("role")}`}
                    options={[...availableRoles(role, t)]}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("nameSurname")}
                  value={values.name}
                  handleChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("onlyAddress")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="number"
                  label={t("phone")}
                  value={values.phone}
                  handleChange={(e) =>
                    setValues({ ...values, phone: e.target.value })
                  }
                />
              </Grid>
              {values.role.includes(allRoles.company) &&
              !values.role.includes(allRoles.admin) &&
              role === allRoles.superadmin ? (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={[
                      { label: "N/A", value: "" },
                      ...(allAdmin || []).map((admin) => ({
                        label: `${admin.name} (${admin.email})`,
                        value: admin.uid,
                      })),
                    ]}
                    label={`${t("admin")} (facoltativo)`}
                    value={values.createdBy}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        createdBy: e.target.value,
                      });
                      setLifts([]);
                    }}
                  />
                </Grid>
              ) : (values.role.includes(allRoles.customer) ||
                  values.role.includes(allRoles.installer) ||
                  values.role.includes(allRoles.respMaintainer)) &&
                !values.role.includes(allRoles.company) &&
                (role === allRoles.superadmin || role === allRoles.admin) ? (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={[
                      { label: "N/A", value: "" },
                      ...(allCompanies || []).map((company) => ({
                        label: `${company.name} (${company.email})`,
                        value: company.uid,
                      })),
                    ]}
                    label={`${t("company")} (facoltativo)`}
                    value={values.createdBy}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        createdBy: e.target.value,
                      });
                      setLifts([]);
                    }}
                  />
                </Grid>
              ) : values.role.includes(allRoles.maintainer) &&
                !values.role.includes(allRoles.respMaintainer) &&
                (role === allRoles.superadmin ||
                  role === allRoles.admin ||
                  role === allRoles.company) ? (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={[
                      { label: "N/A", value: "" },
                      ...(allResponsibles || []).map((resp) => ({
                        label: `${resp.name} (${resp.email})`,
                        value: resp.uid,
                      })),
                    ]}
                    label={`${t("responsible")} (facoltativo)`}
                    value={values.createdBy}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        createdBy: e.target.value,
                        responsible: true,
                      });
                      setLifts([]);
                    }}
                  />
                </Grid>
              ) : null}
              {(values.role.includes(allRoles.respMaintainer) ||
                values.role.includes(allRoles.maintainer) ||
                values.role.includes(allRoles.customer)) &&
                !values.role.includes(allRoles.company) && (
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      handleChange={(e) => setLifts(e.target.value as string[])}
                      value={lifts}
                      multiple
                      label={`${t("assignLifts")}`}
                      options={
                        availableLifts
                          ? (availableLifts || []).map((lift) => ({
                              label: `${lift.name} (${lift.system_number})`,
                              value: `${lift.id}`,
                            }))
                          : []
                      }
                    />
                  </Grid>
                )}
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  email: "",
                  name: "",
                  address: "",
                  phone: "",
                  role: [],
                  createdBy: "",
                  responsible: false,
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
              disabled={disableButton}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateUser;
