import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomSelect from "../../custom/CustomSelect";
import {
  createUser as createUserApi,
  getUsers,
} from "../../../api/services/userService";
import { useSnackbar } from "notistack";
import { AddUserRequest } from "../../../api/requests/userService";
import { roles as allRoles } from "../costants";
import {
  getCompanyElevators,
  getElevators,
} from "../../../api/services/elevatorService";
import CustomTag from "../../custom/CustomTag";
import { greyColor } from "../../custom/utils";

type CreateUserProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  roles: { label: string; value: string }[];
  actualRole: string;
  uid: string;
};

const CreateUser: React.FC<CreateUserProps> = ({
  setBreadcrumb,
  title,
  roles,
  actualRole,
  uid,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [allAdmin, setAllAdmin] = useState<any[]>([]);
  const [allResponsibles, setAllResponsibles] = useState<any[]>([]);
  useEffect(() => {
    if (
      actualRole === allRoles.superadmin ||
      actualRole === allRoles.admin ||
      actualRole === allRoles.company
    ) {
      getUsers("responsabili").then((res) => {
        if (res && res.responsabili) {
          setAllResponsibles(res.responsabili);
        }
      });
    }
    if (actualRole === allRoles.superadmin || actualRole === allRoles.admin) {
      getUsers("aziende").then((res) => {
        if (res && res.aziende) {
          setAllCompanies(res.aziende);
        }
      });
    }
    if (actualRole === allRoles.superadmin) {
      getUsers("admins").then((res) => {
        if (res && res.admins) {
          setAllAdmin(res.admins);
        }
      });
    }
  }, [actualRole]);

  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [values, setValues] = useState({
    role: roles.length === 1 ? [roles[0].value] : [],
    email: "",
    name: "",
    password: "",
    confirm_password: "",
    address: "",
    phone: "",
    createdBy: actualRole === allRoles.respMaintainer ? uid : "",
    responsible: false,
  });

  const [availableLifts, setAvailableLifts] = useState<any[]>([]);
  const [lifts, setLifts] = useState<any[]>([]);
  useEffect(() => {
    if (actualRole === allRoles.respMaintainer) {
      getElevators(allRoles.respMaintainer).then((res) => {
        if (res && res.installations) {
          setAvailableLifts(res.installations);
        } else {
          setAvailableLifts([]);
        }
      });
    } else {
      if (values.createdBy) {
        let tmp = values.createdBy;
        if (values.responsible) {
          const responsible = allResponsibles.filter(
            (r) => r.uid === values.createdBy
          );
          if (responsible.length > 0) {
            tmp = responsible[0].created_by;
          }
        }
        getCompanyElevators(tmp).then((res) => {
          //getElevators(role).then((res) => {
          if (res && res.installations) {
            setAvailableLifts(res.installations);
          } else {
            setAvailableLifts([]);
          }
        });
      } else {
        setAvailableLifts([]);
      }
    }
  }, [values.createdBy, values.responsible, allResponsibles]);

  const handleSubmit = () => {
    setDisableButton(true);
    if (
      values.name &&
      values.email &&
      values.password &&
      values.confirm_password
    ) {
      let tmpRole = values.role;
      if (
        values.role.includes(allRoles.respMaintainer) &&
        values.role.includes(allRoles.maintainer)
      ) {
        tmpRole = values.role.filter((r: string) => r !== allRoles.maintainer);
      }
      createUserApi({
        name: values.name,
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
        role: tmpRole,
        user_info: {
          address: values.address,
          city: "",
          province: "",
          zip_code: "",
          phone: values.phone,
        },
        created_by:
          values.role.includes(allRoles.company) &&
          !values.role.includes(allRoles.admin) &&
          actualRole === allRoles.superadmin
            ? values.createdBy
            : (values.role.includes(allRoles.customer) ||
                values.role.includes(allRoles.installer) ||
                values.role.includes(allRoles.respMaintainer)) &&
              !values.role.includes(allRoles.company) &&
              (actualRole === allRoles.superadmin ||
                actualRole === allRoles.admin)
            ? values.createdBy
            : values.role.includes(allRoles.maintainer) &&
              !values.role.includes(allRoles.respMaintainer) &&
              (actualRole === allRoles.superadmin ||
                actualRole === allRoles.admin ||
                actualRole === allRoles.company)
            ? values.createdBy
            : actualRole === allRoles.respMaintainer
            ? values.createdBy
            : "",
        installations: lifts,
      } as AddUserRequest).then((res) => {
        if (res && res.user) {
          setValues({
            role: [],
            email: "",
            name: "",
            password: "",
            confirm_password: "",
            address: "",
            phone: "",
            createdBy: "",
            responsible: false,
          });
          enqueueSnackbar(
            `${t("userCreatedSuccess")}. ${
              values.role.includes(allRoles.respMaintainer) &&
              values.role.includes(allRoles.maintainer)
                ? t("maintainerAndRespMaintainer")
                : ""
            }`,
            { variant: "success" }
          );
          navigate(mainUrl);
        } else {
          enqueueSnackbar(`${t("userCreatedError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
        setDisableButton(false);
      });
    } else {
      enqueueSnackbar(`${t("fillAllUserFields")}`, {
        variant: "error",
      });
      setDisableButton(false);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                {actualRole === allRoles.respMaintainer ? (
                  <CustomTag
                    label={t("mantainer")}
                    type="filled"
                    color={greyColor}
                  />
                ) : (
                  <CustomSelect
                    options={roles}
                    label={`${t("role")}`}
                    value={values.role}
                    handleChange={(e) => {
                      if (e && e.target && e.target.value) {
                        setValues({
                          ...values,
                          role: [...e.target.value],
                        });
                        if (
                          (e.target.value.includes(allRoles.respMaintainer) ||
                            e.target.value.includes(allRoles.maintainer) ||
                            e.target.value.includes(allRoles.customer)) &&
                          e.target.value.includes(allRoles.company)
                        ) {
                          setLifts([]);
                        }
                      }
                    }}
                    multiple
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("nameSurname")}
                  value={values.name}
                  handleChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("email")}
                  value={values.email}
                  handleChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="password"
                  label={t("password")}
                  value={values.password}
                  handleChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  validator={{
                    error:
                      values.password.length >= 8 &&
                      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])/.test(
                        values.password
                      ),
                    helperText: `${t("passwordContentError")}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="password"
                  label={t("confirmPassword")}
                  value={values.confirm_password}
                  handleChange={(e) =>
                    setValues({ ...values, confirm_password: e.target.value })
                  }
                  validator={{
                    error: values.password !== values.confirm_password,
                    helperText: `${t("passwordDoNotMatch")}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("onlyAddress")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="number"
                  label={t("phone")}
                  value={values.phone}
                  handleChange={(e) =>
                    setValues({ ...values, phone: e.target.value })
                  }
                />
              </Grid>
              {values.role.includes(allRoles.company) &&
              !values.role.includes(allRoles.admin) &&
              actualRole === allRoles.superadmin ? (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={(allAdmin || []).map((admin) => ({
                      label: `${admin.name} (${admin.email})`,
                      value: admin.uid,
                    }))}
                    label={`${t("admin")} (facoltativo)`}
                    value={values.createdBy}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        createdBy: e.target.value,
                      });
                      setLifts([]);
                    }}
                  />
                </Grid>
              ) : (values.role.includes(allRoles.customer) ||
                  values.role.includes(allRoles.installer) ||
                  values.role.includes(allRoles.respMaintainer)) &&
                !values.role.includes(allRoles.company) &&
                (actualRole === allRoles.superadmin ||
                  actualRole === allRoles.admin) ? (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={(allCompanies || []).map((company) => ({
                      label: `${company.name} (${company.email})`,
                      value: company.uid,
                    }))}
                    label={`${t("company")} (facoltativo)`}
                    value={values.createdBy}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        createdBy: e.target.value,
                      });
                      setLifts([]);
                    }}
                  />
                </Grid>
              ) : values.role.includes(allRoles.maintainer) &&
                !values.role.includes(allRoles.respMaintainer) &&
                (actualRole === allRoles.superadmin ||
                  actualRole === allRoles.admin ||
                  actualRole === allRoles.company) ? (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={(allResponsibles || []).map((resp) => ({
                      label: `${resp.name} (${resp.email})`,
                      value: resp.uid,
                    }))}
                    label={`${t("responsible")} (facoltativo)`}
                    value={values.createdBy}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        createdBy: e.target.value,
                        responsible: true,
                      });
                      setLifts([]);
                    }}
                  />
                </Grid>
              ) : null}
              {(values.role.includes(allRoles.respMaintainer) ||
                values.role.includes(allRoles.maintainer) ||
                values.role.includes(allRoles.customer)) &&
                !values.role.includes(allRoles.company) && (
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      handleChange={(e) => setLifts(e.target.value as string[])}
                      value={lifts}
                      multiple
                      label={`${t("assignLifts")}`}
                      options={
                        availableLifts
                          ? (availableLifts || []).map((lift) => ({
                              label: `${lift.name} (${lift.system_number})`,
                              value: lift.id,
                            }))
                          : []
                      }
                    />
                  </Grid>
                )}
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  role: [],
                  email: "",
                  name: "",
                  password: "",
                  confirm_password: "",
                  address: "",
                  phone: "",
                  createdBy: "",
                  responsible: false,
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
              disabled={disableButton}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default CreateUser;
