import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Collapse, Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { mainUrl } from "../costants";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomSelect from "../../custom/CustomSelect";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomText from "../../custom/CustomText";
import { useSnackbar } from "notistack";
import {
  isValidIPv4,
  isValidSSID,
  isValidWPA2,
  prepareConfigJson,
  prepareNetJson,
  prepareNetTestJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import CustomDivider from "../../custom/CustomDivider";
import {
  getElevators,
  getLastData,
} from "../../../api/services/elevatorService";
import CustomFeedback from "../../custom/CustomFeedback";
import {
  checkZfsStatus,
  commit,
  getZfs,
  getZfsFile,
  init,
  uploadFiles,
} from "../../../api/services/configuratorService";
import CustomLoading from "../../custom/CustomLoading";

type WifiConfigurationProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const WifiConfiguration: React.FC<WifiConfigurationProps> = ({
  setBreadcrumb,
  role,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const deviceId = searchParams.get("deviceId");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("wifiConfiguration")}
      </Typography>,
    ]);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [values, setValues] = useState({
    installation: "",
    elevator: deviceId || "",
    contattore_manovra: "",
    contattore_apertura_porte: "",
    contattore_chiusura_porte: "",
    th1: "",
    th2: "",
    speed: "",
    system_power_aggregation: "",
    system_power_triphase: false,
    system_power_value: "",
    doors_power_aggregation: "",
    doors_power_triphase: false,
    doors_power_value: "",
  });
  useEffect(() => {
    if (values.elevator) {
      getZfs(values.elevator).then((res: any) => {
        if (res && res.files) {
          const netFile = res.files.find(
            (file: any) => file.path === "net.json"
          );
          const paramsFile = res.files.find(
            (file: any) => file.path === "params.json"
          );
          if (paramsFile) {
            getZfsFile(values.elevator, paramsFile.id).then((resFile: any) => {
              getLastData(values.elevator, "z", "*").then((res) => {
                if (
                  res &&
                  res.result &&
                  res.result.values &&
                  res.result.values.length > 0 &&
                  res.result.values[0].value &&
                  res.result.values[0].value.CH_type &&
                  res.result.values[0].value.p_limit &&
                  res.result.values[0].value.speed
                ) {
                  setValues({
                    ...values,
                    th1: res.result.values[0].value.p_limit[0] || "",
                    th2: res.result.values[0].value.p_limit[1] || "",
                    contattore_manovra:
                      res.result.values[0].value.CH_type[0] || "",
                    contattore_apertura_porte:
                      res.result.values[0].value.CH_type[1] || "",
                    contattore_chiusura_porte:
                      res.result.values[0].value.CH_type[2] || "",
                    speed: res.result.values[0].value.speed || "",
                    system_power_aggregation:
                      resFile?.CA1?.rolling_mode || "avg",
                    system_power_triphase: resFile?.CA1?.three_phase || false,
                    system_power_value: resFile?.CA1?.offset || "",
                    doors_power_aggregation:
                      resFile?.CA2?.rolling_mode || "avg",
                    doors_power_triphase: resFile?.CA2?.three_phase || false,
                    doors_power_value: resFile?.CA2?.offset || "",
                  });
                } else {
                  setValues({
                    ...values,
                    th1: resFile?.th1 || 0,
                    th2: resFile?.th2 || 0,
                    contattore_manovra: resFile?.c_manovra?.type || "NO",
                    contattore_apertura_porte: resFile?.c_open?.type || "NO",
                    contattore_chiusura_porte: resFile?.c_close?.type || "NO",
                    speed: resFile?.speed || 0,
                    system_power_aggregation:
                      resFile?.CA1?.rolling_mode || "avg",
                    system_power_triphase: resFile?.CA1?.three_phase || false,
                    system_power_value: resFile?.CA1?.offset || "",
                    doors_power_aggregation:
                      resFile?.CA2?.rolling_mode || "avg",
                    doors_power_triphase: resFile?.CA2?.three_phase || false,
                    doors_power_value: resFile?.CA2?.offset || "",
                  });
                }
              });
            });
          }
          if (netFile) {
            getZfsFile(values.elevator, netFile.id).then((resFile) => {
              //find ifcs wifi
              if (resFile && resFile.IFCS && resFile.IFCS.length > 0) {
                const wifiIfcs = resFile.IFCS.find(
                  (ifc: any) => ifc.ifc_name === "wifi"
                );
                if (wifiIfcs) {
                  setWifi({
                    IFCS: [
                      {
                        ifc_name: "wifi",
                        ifc_params: {
                          ssid: wifiIfcs?.ifc_params.ssid || "Default",
                          pwd: wifiIfcs?.ifc_params.pwd || "Default123",
                          dhcp: wifiIfcs?.ifc_params?.dhcp || true,
                          ip: wifiIfcs?.ifc_params?.ip || "",
                          mask: wifiIfcs?.ifc_params?.mask || "",
                          gateway: wifiIfcs?.ifc_params?.gateway || "",
                          dns: wifiIfcs?.ifc_params?.dns || "",
                        },
                      },
                    ],
                  });
                } else {
                  enqueueSnackbar(
                    t("wifiRetrievalError") + " " + (res?.err?.message || ""),
                    {
                      variant: "error",
                    }
                  );
                }
              } else {
                enqueueSnackbar(
                  t("wifiRetrievalError") + " " + (res?.err?.message || ""),
                  {
                    variant: "error",
                  }
                );
              }
            });
          } else {
            enqueueSnackbar(
              t("wifiRetrievalError") + " " + (res?.err?.message || ""),
              {
                variant: "error",
              }
            );
          }
        } else {
          enqueueSnackbar(
            t("wifiRetrievalError") + " " + (res?.err?.message || ""),
            {
              variant: "error",
            }
          );
        }
      });
    }
  }, [values.elevator]);

  const [wifi, setWifi] = useState<any>({
    IFCS: [
      {
        ifc_name: "wifi",
        ifc_params: {
          ssid: "N/A",
          pwd: "N/A",
          dhcp: true,
          ip: "",
          mask: "",
          gateway: "",
          dns: "",
        },
      },
    ],
  });

  const checkFields = () => {
    if (
      !(
        wifi.IFCS[0].ifc_params.ssid &&
        wifi.IFCS[0].ifc_params.pwd &&
        isValidSSID(wifi.IFCS[0].ifc_params.ssid) &&
        isValidWPA2(wifi.IFCS[0].ifc_params.pwd) &&
        (wifi.IFCS[0].ifc_params.dhcp ||
          (wifi.IFCS[0].ifc_params.ip &&
            wifi.IFCS[0].ifc_params.subnetMask &&
            wifi.IFCS[0].ifc_params.gateway &&
            wifi.IFCS[0].ifc_params.dns &&
            isValidIPv4(wifi.IFCS[0].ifc_params.ip) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.subnetMask) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.gateway) &&
            isValidIPv4(wifi.IFCS[0].ifc_params.dns)))
      )
    ) {
      return false;
    }
    return true;
  };

  const [procedi, setProcedi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  useEffect(() => {
    getElevators(role).then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, []);

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(values.elevator)
        .then((res) => {
          if (res && res.job && res.job.status) {
            if (res.job.status === "pending") {
              setRunningZfs(true);
            } else {
              setRunningZfs(false);
            }
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          setRunningZfs(false);
        });
    };
    if (values.elevator) {
      checkStatus();
      const intervalId = setInterval(checkStatus, 30000);
      return () => clearInterval(intervalId);
    }
  }, [values.elevator]);

  const handleSubmit = () => {
    const netFile = {
      file: new File(
        [
          new TextEncoder().encode(
            JSON.stringify(prepareNetJson(wifi, { IFCS: [] }, "iot.secure"))
          ),
        ] as BlobPart[],
        "net.json"
      ),
    };
    const paramsFile = {
      file: new File(
        [
          new TextEncoder().encode(
            JSON.stringify(
              prepareParamsJson(
                Number(values.th1),
                Number(values.th2),
                values.contattore_manovra as "NO" | "NC",
                values.contattore_apertura_porte as "NO" | "NC",
                values.contattore_chiusura_porte as "NO" | "NC",
                Number(values.speed),
                values.system_power_aggregation || "avg",
                values.system_power_triphase,
                Number(values.system_power_value),
                values.doors_power_aggregation || "avg",
                values.doors_power_triphase,
                Number(values.doors_power_value)
              )
            )
          ),
        ] as BlobPart[],
        "params.json"
      ),
    };
    const netTestFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareNetTestJson)),
        ] as BlobPart[],
        "net_test.json"
      ),
    };
    const sensorsFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
        ] as BlobPart[],
        "sensors.json"
      ),
    };
    const configFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
        ] as BlobPart[],
        "config.json"
      ),
    };
    init(values.elevator).then(
      async () =>
        await uploadFiles(values.elevator, [
          netFile,
          paramsFile,
          sensorsFile,
          configFile,
          netTestFile,
        ]).then(async (res: any) => {
          if (res && res.files) {
            await commit(values.elevator, true).then((r: any) => {
              if (r && r.version) {
                enqueueSnackbar(t("updateWifiSuccess"), {
                  variant: "success",
                });
                setValues({
                  installation: "",
                  elevator: "",
                  contattore_manovra: "",
                  contattore_apertura_porte: "",
                  contattore_chiusura_porte: "",
                  th1: "",
                  th2: "",
                  speed: "",
                  system_power_aggregation: "",
                  system_power_triphase: false,
                  system_power_value: "",
                  doors_power_aggregation: "",
                  doors_power_triphase: false,
                  doors_power_value: "",
                });
                navigate(mainUrl);
              } else {
                enqueueSnackbar(
                  `${t("updateWifiError")} ${res?.err?.message || ""}`,
                  {
                    variant: "error",
                  }
                );
              }
            });
          }
        })
    );
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("wifiConfiguration")}`}
          goBack={() => {
            navigate(-1);
          }}
        />
      </Grid>
      {runningZfs && (
        <Grid item xs={12}>
          <CustomFeedback label={t("workInProgress")} type="warning" />
        </Grid>
      )}
      {procedi ? (
        <Grid item xs={12} md={8}>
          <CustomFeedback type="error" label={`${t("wifiWarningMessage")}`} />
        </Grid>
      ) : values.elevator &&
        wifi.IFCS[0].ifc_params.ssid !== "N/A" &&
        wifi.IFCS[0].ifc_params.pwd !== "N/A" ? (
        <Grid item xs={12} md={8}>
          <CustomFeedback
            type="info"
            label={`${t("wifiInfoMessage")}`
              .replace("[SSID]", wifi.IFCS[0].ifc_params.ssid)
              .replace("[PWD]", wifi.IFCS[0].ifc_params.pwd)}
          />
        </Grid>
      ) : undefined}
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  label={`${t("systemNumber")}`}
                  value={values.elevator}
                  handleChange={(e) =>
                    setValues({ ...values, elevator: e.target.value })
                  }
                  options={[
                    ...elevators.map((e) => {
                      return {
                        label: `${e.system_number} - ${e.name}`,
                        value: e.id,
                      };
                    }),
                  ]}
                />
              </Grid>
              {procedi && (
                <>
                  <Grid item xs={12}>
                    <CustomDivider label={`${t("wifi")}`} textAlign="center" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label={t("networkSsid")}
                      value={wifi.IFCS[0].ifc_params.ssid}
                      handleChange={(e) =>
                        setWifi({
                          ...wifi,
                          IFCS: [
                            {
                              ...wifi.IFCS[0],
                              ifc_params: {
                                ...wifi.IFCS[0].ifc_params,
                                ssid: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                      validator={{
                        error:
                          !!wifi.IFCS[0].ifc_params.ssid &&
                          !isValidSSID(wifi.IFCS[0].ifc_params.ssid),
                        helperText: `${t("invalidSsid")}`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label={t("networkPassword")}
                      value={wifi.IFCS[0].ifc_params.pwd}
                      handleChange={(e) =>
                        setWifi({
                          ...wifi,
                          IFCS: [
                            {
                              ...wifi.IFCS[0],
                              ifc_params: {
                                ...wifi.IFCS[0].ifc_params,
                                pwd: e.target.value,
                              },
                            },
                          ],
                        })
                      }
                      validator={{
                        error:
                          !!wifi.IFCS[0].ifc_params.pwd &&
                          !isValidWPA2(wifi.IFCS[0].ifc_params.pwd),
                        helperText: `${t("invalidPassword")}`,
                      }}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <CustomIconButton
                          type="outlined"
                          icon={
                            showPassword ? (
                              <VisibilityOff style={{ fontSize: "18px" }} />
                            ) : (
                              <Visibility style={{ fontSize: "18px" }} />
                            )
                          }
                          onClick={handleShowPassword}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomText label={`${t("ipv4")}`} type="h6" />
                  </Grid>
                  <Grid item xs={12}>
                    <Switch
                      checked={!wifi.IFCS[0].ifc_params.dhcp}
                      onChange={() =>
                        setWifi({
                          ...wifi,
                          IFCS: [
                            {
                              ...wifi.IFCS[0],
                              ifc_params: {
                                ...wifi.IFCS[0].ifc_params,
                                dhcp: !wifi.IFCS[0].ifc_params.dhcp,
                              },
                            },
                          ],
                        })
                      }
                    />
                    {!wifi.IFCS[0].ifc_params.dhcp
                      ? " " + t("manual")
                      : " " + t("automaticDhcp")}
                  </Grid>
                  <Grid item>
                    <Collapse
                      in={!wifi.IFCS[0].ifc_params.dhcp}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <CustomInput
                            label={t("ipAddress")}
                            value={wifi.IFCS[0].ifc_params.ip}
                            handleChange={(e) =>
                              setWifi({
                                ...wifi,
                                IFCS: [
                                  {
                                    ...wifi.IFCS[0],
                                    ifc_params: {
                                      ...wifi.IFCS[0].ifc_params,
                                      ip: e.target.value,
                                    },
                                  },
                                ],
                              })
                            }
                            validator={{
                              error:
                                !!wifi.IFCS[0].ifc_params.ip &&
                                !isValidIPv4(wifi.IFCS[0].ifc_params.ip),
                              helperText: `${t("invalidIpv4Address")}`,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomInput
                            label={t("subnetMask")}
                            value={wifi.IFCS[0].ifc_params.subnetMask}
                            handleChange={(e) =>
                              setWifi({
                                ...wifi,
                                IFCS: [
                                  {
                                    ...wifi.IFCS[0],
                                    ifc_params: {
                                      ...wifi.IFCS[0].ifc_params,
                                      mask: e.target.value,
                                    },
                                  },
                                ],
                              })
                            }
                            validator={{
                              error:
                                !!wifi.IFCS[0].ifc_params.subnetMask &&
                                !isValidIPv4(
                                  wifi.IFCS[0].ifc_params.subnetMask
                                ),
                              helperText: `${t("invalidIpv4Address")}`,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomInput
                            label={t("gateway")}
                            value={wifi.IFCS[0].ifc_params.gateway}
                            handleChange={(e) =>
                              setWifi({
                                ...wifi,
                                IFCS: [
                                  {
                                    ...wifi.IFCS[0],
                                    ifc_params: {
                                      ...wifi.IFCS[0].ifc_params,
                                      gateway: e.target.value,
                                    },
                                  },
                                ],
                              })
                            }
                            validator={{
                              error:
                                !!wifi.IFCS[0].ifc_params.gateway &&
                                !isValidIPv4(wifi.IFCS[0].ifc_params.gateway),
                              helperText: `${t("invalidIpv4Address")}`,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomInput
                            label={t("dns")}
                            value={wifi.IFCS[0].ifc_params.dns}
                            handleChange={(e) =>
                              setWifi({
                                ...wifi,
                                IFCS: [
                                  {
                                    ...wifi.IFCS[0],
                                    ifc_params: {
                                      ...wifi.IFCS[0].ifc_params,
                                      dns: e.target.value,
                                    },
                                  },
                                ],
                              })
                            }
                            validator={{
                              error:
                                !!wifi.IFCS[0].ifc_params.dns &&
                                !isValidIPv4(wifi.IFCS[0].ifc_params.dns),
                              helperText: `${t("invalidIpv4Address")}`,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              )}
              {procedi ? (
                <Grid item container spacing={2}>
                  <Grid item xs={12} md>
                    <CustomButton
                      confirm
                      fullWidth
                      label={t("cancel")}
                      onClick={() => {
                        setWifi({
                          IFCS: [
                            {
                              ifc_name: "wifi",
                              ifc_params: {
                                ssid: "Default",
                                pwd: "Default123",
                                dhcp: true,
                                ip: "",
                                mask: "",
                                gateway: "",
                                dns: "",
                              },
                            },
                          ],
                        });
                        setValues({
                          installation: "",
                          elevator: "",
                          contattore_manovra: "",
                          contattore_apertura_porte: "",
                          contattore_chiusura_porte: "",
                          th1: "",
                          th2: "",
                          speed: "",
                          system_power_aggregation: "",
                          system_power_triphase: false,
                          system_power_value: "",
                          doors_power_aggregation: "",
                          doors_power_triphase: false,
                          doors_power_value: "",
                        });
                        setProcedi(false);
                      }}
                      type="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md>
                    <CustomButton
                      fullWidth
                      label={t("ok")}
                      onClick={() => {
                        const correctFields = checkFields();
                        if (correctFields) {
                          handleSubmit();
                        } else {
                          if (!correctFields) {
                            enqueueSnackbar(t("invalidFields"), {
                              variant: "error",
                            });
                          } else {
                            enqueueSnackbar(t("completeAllFields"), {
                              variant: "error",
                            });
                          }
                        }
                      }}
                      type="contained"
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item container spacing={2}>
                  <Grid item xs={12} md>
                    <CustomButton
                      confirm
                      fullWidth
                      label={t("cancel")}
                      onClick={() => {
                        setWifi({
                          IFCS: [
                            {
                              ifc_name: "wifi",
                              ifc_params: {
                                ssid: "Default",
                                pwd: "Default123",
                                dhcp: true,
                                ip: "",
                                mask: "",
                                gateway: "",
                                dns: "",
                              },
                            },
                          ],
                        });

                        setValues({
                          installation: "",
                          elevator: "",
                          contattore_manovra: "",
                          contattore_apertura_porte: "",
                          contattore_chiusura_porte: "",
                          th1: "",
                          th2: "",
                          speed: "",
                          system_power_aggregation: "",
                          system_power_triphase: false,
                          system_power_value: "",
                          doors_power_aggregation: "",
                          doors_power_triphase: false,
                          doors_power_value: "",
                        });
                        navigate(mainUrl);
                      }}
                      type="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md>
                    <CustomButton
                      fullWidth
                      label={t("proceed")}
                      onClick={() => {
                        if (values.elevator) {
                          setProcedi(true);
                        } else {
                          enqueueSnackbar(t("selectElevator"), {
                            variant: "error",
                          });
                        }
                      }}
                      type="contained"
                      disabled={
                        wifi.IFCS[0].ifc_params.ssid === "N/A" ||
                        wifi.IFCS[0].ifc_params.pwd === "N/A"
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default WifiConfiguration;
