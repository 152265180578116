import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomText from "../../custom/CustomText";
import CustomSelect from "../../custom/CustomSelect";
import { unassignBoardType } from "../../../api/services/allocatorService";
import { useSnackbar } from "notistack";

type CustomButtonProps = {
  open: boolean;
  handleClose: () => void;
  details: { adminName: string; adminUid: string; boardType: string[] };
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnassignBoard: React.FC<CustomButtonProps> = ({
  open,
  handleClose,
  details,
  setReload,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelected(details.boardType);
  }, [details.boardType]);

  const handleUnassign = () => {
    if (selected.length === 0) {
      enqueueSnackbar(t("selectBoardType"), { variant: "error" });
    } else {
      unassignBoardType(details.adminUid, selected).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("unassignSuccess"), { variant: "success" });
          handleClose();
          setReload((prev) => !prev);
        } else {
          enqueueSnackbar(`${t("unassignError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              <CustomText type="subtitle1" label={`${t("unassignTitle")}`} />
            </Grid>
            <Grid item>
              <b>{details.adminName}</b>
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ paddingLeft: "16px", paddingTop: "8px" }}>
            <CustomSelect
              label={`${t("boardType")}`}
              value={selected}
              multiple
              options={[
                ...details.boardType.map((b) => ({
                  label: `${t(b)}`,
                  value: b,
                })),
              ]}
              handleChange={(e: any) => setSelected(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-around"
          sx={{ paddingBottom: "16px" }}
        >
          <Grid item xs={12} md={4}>
            <CustomButton
              fullWidth
              label={t("close")}
              type="outlined"
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomButton
              fullWidth
              label={t("ok")}
              type="contained"
              onClick={handleUnassign}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UnassignBoard;
