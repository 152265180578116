import { logout } from "../../api/services/loginService";
import { useNavigate } from "react-router-dom";
import React, { MouseEvent, useEffect, useState } from "react";
import { routes } from "../pages/costants";
import { AppBar, Breadcrumbs, Grid, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo_orizzontale.svg";
import { useTranslation } from "react-i18next";
import {
  AccountCircleOutlined,
  PowerSettingsNewOutlined,
  PersonOutlineOutlined,
  NavigateNext,
} from "@mui/icons-material";
import { darkColor, greyColor } from "../custom/utils";
import CustomIconButton from "../custom/CustomIconButton";
import CustomDivider from "../custom/CustomDivider";
import CustomText from "../custom/CustomText";
import { getProfile } from "../../api/services/userService";
import CustomTag from "../custom/CustomTag";

type HeaderProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
  isMobile: boolean;
  breadcrumbs: any[];
  configuratorRunning: boolean;
};

const Header: React.FC<HeaderProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
  isMobile,
  breadcrumbs,
  configuratorRunning,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const logOut = () => {
    logout().then((res) => {
      if (res && res.status && res.status === "success") {
        localStorage.removeItem("baglini_role");
        navigate(res.location);
      }
    });
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<any>({});
  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfile({
          ...res.user,
        });
      }
      setLoading(false);
    });
  }, []);

  const checkRole = (role: string) => {
    if (role === "customer") {
      return t("endUser");
    }
    if (role === "responsabile") {
      return t("mantainerResponsible");
    }
    if (role === "installer") {
      return t("installer");
    }
    if (role === "admin") {
      return t("admin");
    }
    if (role === "manutentore") {
      return t("mantainer");
    }
    if (role === "azienda") {
      return t("company");
    }
    return role;
  };

  if (loading) {
    return null;
  }

  return (
    <AppBar
      variant="outlined"
      position="fixed"
      style={{ backgroundColor: darkColor, zIndex: 1387 }}
    >
      <Grid container alignItems="center">
        <Grid item>
          {isMobile ? (
            <CustomIconButton
              disabled={configuratorRunning}
              icon={<MenuIcon htmlColor="#ffffff" />}
              onClick={() => SetNavbarIsCollapsed(!navbar_collapsed)}
            />
          ) : (
            <img
              style={{ cursor: "pointer", height: "60px" }}
              alt="logo"
              src={logo}
              onClick={() => {
                navigate(routes.mainPage);
                SetNavbarIsCollapsed(true);
              }}
            />
          )}
        </Grid>
        {!isMobile && (
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext htmlColor="#ffffff" fontSize="small" />}
              style={{ paddingLeft: "32px", textTransform: "uppercase" }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
        )}
        <Grid item xs />
        {!isMobile && (
          <Grid item style={{ textAlign: "right" }}>
            <CustomTag
              label={checkRole(
                localStorage.getItem("baglini_role") || profile.roles[0]
              )}
              color={greyColor}
              type="filled"
            />
            <CustomText label={profile.email} type="body2" />
          </Grid>
        )}
        <Grid item>
          <CustomIconButton
            disabled={configuratorRunning}
            icon={<AccountCircleOutlined htmlColor="#ffffff" />}
            onClick={(event: MouseEvent<HTMLDivElement>) =>
              setAnchorEl(event.currentTarget)
            }
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            i18n.language === "en"
              ? i18n.changeLanguage("it")
              : i18n.changeLanguage("en");
          }}
        >
          {i18n.language === "en" ? "🇮🇹 Italiano" : "🇬🇧 English"}
        </MenuItem>
        <CustomDivider />
        <MenuItem
          onClick={() => {
            navigate(routes.profile);
          }}
        >
          <PersonOutlineOutlined />
          {t("profile")}
        </MenuItem>
        <MenuItem onClick={logOut}>
          <PowerSettingsNewOutlined />
          {t("logout")}
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
