import { isDev } from "../../components/pages/costants";
import {
  CreateElevatorRequest,
  GetExportInfoRequest,
  UpdateElevatorRequest,
  updateAlarmsCalibrationRequest,
} from "../requests/elevatorService";
import {
  ZDMFetch,
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import {
  getExportInfoUrl,
  createElevatorUrl,
  deleteElevatorUrl,
  getAlarmsUrl,
  getBoardTypeUrl,
  getCompanyElevatorsUrl,
  getContainersUrl,
  getElevatorUrl,
  getElevatorsUrl,
  sendJobUrl,
  updateElevatorUrl,
  updateAlarmsCalibrationUrl,
  getElevatorAlarmsUrl,
  getAlarmsCalibrationUrl,
  getLastDataUrl,
} from "./url";

export const createElevator = (body: CreateElevatorRequest) => {
  return verticalFetch(methodPost, createElevatorUrl, JSON.stringify(body));
};

export const getElevators = (role: string) => {
  return verticalFetch(methodGet, getElevatorsUrl(role));
};

export const getCompanyElevators = (id: string) => {
  return verticalFetch(methodGet, getCompanyElevatorsUrl(id));
};

export const getElevator = (id: string) => {
  return verticalFetch(methodGet, getElevatorUrl(id));
};

export const updateElevator = (r: UpdateElevatorRequest) => {
  return verticalFetch(methodPut, updateElevatorUrl(r.id), JSON.stringify(r));
};

export const deleteElevator = (id: string, role: string) => {
  return verticalFetch(
    methodDelete,
    deleteElevatorUrl(role),
    JSON.stringify({ installation_id: id })
  );
};

export const getBoardType = () => {
  return verticalFetch(methodGet, getBoardTypeUrl);
};

export const getContainers = (role: string) => {
  return verticalFetch(methodGet, getContainersUrl(role));
};

export const sendJob = (id: string, jobName: string, body: any) => {
  return ZDMFetch(
    methodPost,
    sendJobUrl(id, jobName),
    JSON.stringify({ value: body })
  );
};

export const getAlarms = (start: string, end: string, role: string) => {
  if (isDev) {
    return new Promise((resolve) => {
      resolve({
        alarms: [
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T10:28:00.726Z",
            created_at: "2025-03-20T10:30:26.108163Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T10:11:01.903Z",
            finish: "2025-03-20T10:17:01.913Z",
            created_at: "2025-03-20T10:13:26.010679Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T09:55:23.121Z",
            finish: "2025-03-20T09:58:46.868Z",
            created_at: "2025-03-20T09:57:26.081323Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T08:45:01.08Z",
            finish: "2025-03-20T09:49:13.869Z",
            created_at: "2025-03-20T08:47:26.128438Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:45:56.275435Z",
            finish: "2025-03-20T06:45:56.275435Z",
            created_at: "2025-03-20T06:45:56.27559Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:42:56.236975Z",
            finish: "2025-03-20T06:42:56.236975Z",
            created_at: "2025-03-20T06:42:56.2371Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:41:56.270221Z",
            finish: "2025-03-20T06:41:56.270221Z",
            created_at: "2025-03-20T06:41:56.270341Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:40:56.316817Z",
            finish: "2025-03-20T06:40:56.316817Z",
            created_at: "2025-03-20T06:40:56.316983Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:39:56.254343Z",
            finish: "2025-03-20T06:39:56.254343Z",
            created_at: "2025-03-20T06:39:56.254474Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:33:56.235516Z",
            finish: "2025-03-20T06:33:56.235516Z",
            created_at: "2025-03-20T06:33:56.235658Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:32:56.224698Z",
            finish: "2025-03-20T06:32:56.224698Z",
            created_at: "2025-03-20T06:32:56.224817Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:21:56.270921Z",
            finish: "2025-03-20T06:21:56.270921Z",
            created_at: "2025-03-20T06:21:56.271034Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:05:56.275644Z",
            finish: "2025-03-20T06:05:56.275644Z",
            created_at: "2025-03-20T06:05:56.275734Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:04:56.263975Z",
            finish: "2025-03-20T06:04:56.263975Z",
            created_at: "2025-03-20T06:04:56.264104Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T06:03:56.266887Z",
            finish: "2025-03-20T06:03:56.266887Z",
            created_at: "2025-03-20T06:03:56.267001Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T05:55:56.248278Z",
            finish: "2025-03-20T05:55:56.248278Z",
            created_at: "2025-03-20T05:55:56.248399Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-20T05:54:56.213032Z",
            finish: "2025-03-20T05:54:56.213032Z",
            created_at: "2025-03-20T05:54:56.213201Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:50:56.268572Z",
            finish: "2025-03-19T06:50:56.268572Z",
            created_at: "2025-03-19T06:50:56.268675Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:49:56.274827Z",
            finish: "2025-03-19T06:49:56.274827Z",
            created_at: "2025-03-19T06:49:56.274942Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:48:56.283701Z",
            finish: "2025-03-19T06:48:56.283701Z",
            created_at: "2025-03-19T06:48:56.283838Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:46:56.264349Z",
            finish: "2025-03-19T06:46:56.264349Z",
            created_at: "2025-03-19T06:46:56.264454Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:45:56.324185Z",
            finish: "2025-03-19T06:45:56.324185Z",
            created_at: "2025-03-19T06:45:56.324319Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:20:56.257802Z",
            finish: "2025-03-19T06:20:56.257802Z",
            created_at: "2025-03-19T06:20:56.257982Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:19:56.274014Z",
            finish: "2025-03-19T06:19:56.274014Z",
            created_at: "2025-03-19T06:19:56.274112Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:04:56.317397Z",
            finish: "2025-03-19T06:04:56.317397Z",
            created_at: "2025-03-19T06:04:56.317564Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T06:03:56.309042Z",
            finish: "2025-03-19T06:03:56.309042Z",
            created_at: "2025-03-19T06:03:56.309139Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T05:58:56.262293Z",
            finish: "2025-03-19T05:58:56.262293Z",
            created_at: "2025-03-19T05:58:56.262411Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T05:57:56.268894Z",
            finish: "2025-03-19T05:57:56.268894Z",
            created_at: "2025-03-19T05:57:56.268972Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T05:56:56.252352Z",
            finish: "2025-03-19T05:56:56.252352Z",
            created_at: "2025-03-19T05:56:56.252477Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T05:55:56.292449Z",
            finish: "2025-03-19T05:55:56.292449Z",
            created_at: "2025-03-19T05:55:56.292598Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T05:50:56.262133Z",
            finish: "2025-03-19T05:50:56.262133Z",
            created_at: "2025-03-19T05:50:56.26226Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-19T05:49:56.255903Z",
            finish: "2025-03-19T05:49:56.255903Z",
            created_at: "2025-03-19T05:49:56.256034Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T15:24:56.282605Z",
            finish: "2025-03-18T15:24:56.282605Z",
            created_at: "2025-03-18T15:24:56.282739Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T15:09:56.225421Z",
            finish: "2025-03-18T15:09:56.225421Z",
            created_at: "2025-03-18T15:09:56.225527Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T15:08:56.269468Z",
            finish: "2025-03-18T15:08:56.269468Z",
            created_at: "2025-03-18T15:08:56.269541Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T15:07:56.253938Z",
            finish: "2025-03-18T15:07:56.253938Z",
            created_at: "2025-03-18T15:07:56.254032Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T10:02:56.257775Z",
            finish: "2025-03-18T10:02:56.257775Z",
            created_at: "2025-03-18T10:02:56.257871Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T10:01:56.26982Z",
            finish: "2025-03-18T10:01:56.26982Z",
            created_at: "2025-03-18T10:01:56.26989Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T09:46:56.256929Z",
            finish: "2025-03-18T09:46:56.256929Z",
            created_at: "2025-03-18T09:46:56.257047Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T09:45:56.207061Z",
            finish: "2025-03-18T09:45:56.207061Z",
            created_at: "2025-03-18T09:45:56.207196Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T08:48:12.204Z",
            finish: "2025-03-20T08:39:49.673Z",
            created_at: "2025-03-18T08:50:26.072774Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:45:56.212139Z",
            finish: "2025-03-18T06:45:56.212139Z",
            created_at: "2025-03-18T06:45:56.212286Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:44:56.273945Z",
            finish: "2025-03-18T06:44:56.273945Z",
            created_at: "2025-03-18T06:44:56.274059Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:43:56.278777Z",
            finish: "2025-03-18T06:43:56.278777Z",
            created_at: "2025-03-18T06:43:56.278933Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:42:56.260992Z",
            finish: "2025-03-18T06:42:56.260992Z",
            created_at: "2025-03-18T06:42:56.261085Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:40:56.270556Z",
            finish: "2025-03-18T06:40:56.270556Z",
            created_at: "2025-03-18T06:40:56.270669Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:36:56.270986Z",
            finish: "2025-03-18T06:36:56.270986Z",
            created_at: "2025-03-18T06:36:56.271118Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:35:56.226826Z",
            finish: "2025-03-18T06:35:56.226826Z",
            created_at: "2025-03-18T06:35:56.22694Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:20:56.278694Z",
            finish: "2025-03-18T06:20:56.278694Z",
            created_at: "2025-03-18T06:20:56.278791Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:19:56.274402Z",
            finish: "2025-03-18T06:19:56.274402Z",
            created_at: "2025-03-18T06:19:56.274495Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:07:56.260689Z",
            finish: "2025-03-18T06:07:56.260689Z",
            created_at: "2025-03-18T06:07:56.260857Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:06:56.216559Z",
            finish: "2025-03-18T06:06:56.216559Z",
            created_at: "2025-03-18T06:06:56.216649Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T06:05:56.267578Z",
            finish: "2025-03-18T06:05:56.267578Z",
            created_at: "2025-03-18T06:05:56.267702Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T05:59:56.263129Z",
            finish: "2025-03-18T05:59:56.263129Z",
            created_at: "2025-03-18T05:59:56.263277Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T05:58:56.551928Z",
            finish: "2025-03-18T05:58:56.551928Z",
            created_at: "2025-03-18T05:58:56.552072Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T05:35:56.257748Z",
            finish: "2025-03-18T05:35:56.257748Z",
            created_at: "2025-03-18T05:35:56.257896Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T05:34:56.244997Z",
            finish: "2025-03-18T05:34:56.244997Z",
            created_at: "2025-03-18T05:34:56.245633Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-18T04:27:24.238Z",
            finish: "2025-03-18T04:29:46.781Z",
            created_at: "2025-03-18T04:29:26.145122Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T07:00:56.258309Z",
            finish: "2025-03-17T07:00:56.258309Z",
            created_at: "2025-03-17T07:00:56.258421Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:59:56.283518Z",
            finish: "2025-03-17T06:59:56.283518Z",
            created_at: "2025-03-17T06:59:56.283669Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:58:56.233072Z",
            finish: "2025-03-17T06:58:56.233072Z",
            created_at: "2025-03-17T06:58:56.233197Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:57:56.286028Z",
            finish: "2025-03-17T06:57:56.286028Z",
            created_at: "2025-03-17T06:57:56.286159Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:54:56.197994Z",
            finish: "2025-03-17T06:54:56.197994Z",
            created_at: "2025-03-17T06:54:56.198101Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:53:56.259287Z",
            finish: "2025-03-17T06:53:56.259287Z",
            created_at: "2025-03-17T06:53:56.259443Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:50:56.21301Z",
            finish: "2025-03-17T06:50:56.21301Z",
            created_at: "2025-03-17T06:50:56.213124Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:49:56.284704Z",
            finish: "2025-03-17T06:49:56.284704Z",
            created_at: "2025-03-17T06:49:56.284895Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:34:56.263513Z",
            finish: "2025-03-17T06:34:56.263513Z",
            created_at: "2025-03-17T06:34:56.26364Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:33:56.21991Z",
            finish: "2025-03-17T06:33:56.21991Z",
            created_at: "2025-03-17T06:33:56.220019Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:22:56.253721Z",
            finish: "2025-03-17T06:22:56.253721Z",
            created_at: "2025-03-17T06:22:56.253865Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:21:56.266454Z",
            finish: "2025-03-17T06:21:56.266454Z",
            created_at: "2025-03-17T06:21:56.26656Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:06:56.202345Z",
            finish: "2025-03-17T06:06:56.202345Z",
            created_at: "2025-03-17T06:06:56.202466Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:05:56.279109Z",
            finish: "2025-03-17T06:05:56.279109Z",
            created_at: "2025-03-17T06:05:56.279284Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T06:00:56.257369Z",
            finish: "2025-03-17T06:00:56.257369Z",
            created_at: "2025-03-17T06:00:56.257477Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T05:59:56.278545Z",
            finish: "2025-03-17T05:59:56.278545Z",
            created_at: "2025-03-17T05:59:56.278643Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T05:58:56.289195Z",
            finish: "2025-03-17T05:58:56.289195Z",
            created_at: "2025-03-17T05:58:56.289327Z",
          },
          {
            installation_id: "dev-9dixu50g02r1",
            installation_name:
              "VIA DI PALAZZETTO,9 - SAN GIULIANO TERME [05334]",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "elevate_blocking_error_ERRFS_NIHIL",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-17T04:43:56.254577Z",
            finish: "2025-03-17T04:43:56.254577Z",
            created_at: "2025-03-17T04:43:56.254743Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-14T15:13:46.791Z",
            finish: "2025-03-17T11:31:34.661Z",
            created_at: "2025-03-14T15:15:56.092654Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-03-14T11:49:27.055Z",
            created_at: "2025-03-14T11:51:56.135081Z",
          },
          {
            installation_id: "dev-9hf0u93rgk75",
            installation_name: "Ufficio Controlli Tappatà",
            azienda: "1729236666",
            azienda_name: "Pala Todisco Palazzetto",
            admin: "Rinado Papa",
            tag: "offline",
            level: "warning",
            info: {},
            overload: false,
            out_of_order: false,
            blocking_err: 0,
            start: "2025-02-19T16:03:00.566Z",
            finish: "2025-03-14T10:30:51.736Z",
            created_at: "2025-02-19T16:05:17.051148Z",
          },
        ],
        total_count: 79,
      });
    });
  }
  return verticalFetch(methodGet, getAlarmsUrl(start, end, role));
};

export const getElevatorAlarms = (id: string, end: boolean) => {
  return verticalFetch(methodGet, getElevatorAlarmsUrl(id, end));
};

export const updateAlarmsCalibration = (
  req: updateAlarmsCalibrationRequest
) => {
  return verticalFetch(
    methodPut,
    updateAlarmsCalibrationUrl(req.installation_id),
    JSON.stringify(req)
  );
};

export const getAlarmsCalibration = (id: string) => {
  return verticalFetch(methodGet, getAlarmsCalibrationUrl(id));
};

export const getExportInfo = (info: GetExportInfoRequest) => {
  return verticalFetch(methodGet, getExportInfoUrl(info));
};

export const getLastData = (id: string, tag: string, variable: string) => {
  if (isDev) {
    return new Promise((resolve) => {
      resolve({
        result: {
          first_ts: "2024-10-17T15:08:11Z",
          last_ts: "2024-10-17T15:08:11Z",
          values: [
            {
              value: {
                CH_type: ["NO", "NO", "NC"],
                p_limit: [10000, 100],
                speed: 1,
                ts: 1729177691,
              },
              ts: "2024-10-17T15:08:11Z",
            },
          ],
        },
      });
    });
  }
  return verticalFetch(methodGet, getLastDataUrl(id, tag, variable));
};
