import { Route, Routes, useNavigate } from "react-router-dom";
import {
  chartPageUrl,
  companyListToChartUrl,
  companyListToDownloadDataUrl,
  downloadPageUrl,
  mainUrl,
  roles,
  routes,
  updateElevatorUrl,
  updateUserUrl,
  usersListUrl,
} from "./costants";
import Profile from "./profile/Profile";
import { getProfile } from "../../api/services/userService";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HomePage from "./homePages/HomePage";
import Navbar from "../navbar/Navbar";
import InstallationPage from "./installation/InstallationPage";
import UpdateUser from "./updateUser/UpdateUser";
import Header from "../header/Header";
import ElevatorListToChart from "./installationsList/ElevatorListToChart";
import Alarm from "./alarms/Alarm";
import DataHistory from "./dataExportDownload/DataHistory";
import ParamsElevator from "./paramsElevator/ParamsElevator";
import CreateElevator from "./createElevator/CreateElevator";
import ElevatorsList from "./elevatorsList/ElevatorsList";
import UpdateElevator from "./updateElevator/UpdateElevator";
import CustomLoading from "../custom/CustomLoading";
import ConfigurationParams from "./configuration/ConfigurationParams";
import Configuration from "./configuration/Configuration";
import CreateUser from "./createUser/CreateUser";
import AssignElevator from "./assignElevator/AssignElevator";
import ElevatorHistory from "./elevatorsHistory/ElevatorsHistory";
import UsersList from "./usersList/UsersList";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ConfigurationEnd from "./configuration/ConfigurationEnd";
import DeleteElevators from "./deleteElevators/DeleteElevators";
import CreateMaintenance from "./createMaintenance/CreateMaintenance";
import MaintenanceList from "./maintenanceList/MaintenanceList";
import AssignBoard from "./assignBoard/AssignBoard";
import AlarmsThresholds from "./alarmsThresholds/AlarmsThresholds";
import TestBatteriaTampone from "./testBatteriaTampone/TestBatteriaTampone";
import AzMaintenance from "./azMaintenance/AzMaintenance";
import Overview from "./overview/Overview";
import DownloadData from "./downloadData/DownloadData";
import AzUserMaintenance from "./azUserMaintenance/AzUserMaintenance";
import CompaniesOverview from "./overview/companyOverview";
import WifiConfiguration from "./wifiConfiguration/WifiConfiguration";
import { availableListRoles, availableRoles } from "./createUser/utils";

const MainPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [navbarCollapsed, setNavbarCollapsed] = useState<boolean>(true); //booleano per visualizzare o collassare la navbar nel caso di schermi piccoli
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]); //array di oggetti da visualizzare nel breadcrumb
  const [loading, setLoading] = useState<boolean>(true); //booleano per capire quando finisce la verifica del login e quindi caricare la pagina
  const [role, setRole] = useState<string>(""); //stringa che indica il ruolo dell'utente loggato
  const [uid, setUid] = useState<string>(""); //stringa che indica l'uid dell'utente loggato
  const [configuratorRunning, setConfiguratorRunning] =
    useState<boolean>(false); //booleano per capire se il configuratore è in esecuzione

  //verifica che l'utente sia loggato e in base al ruolo gli assegna le giuste pagine da visualizzare
  useEffect(() => {
    getProfile().then((res: any) => {
      if (!res || !res.user) {
        navigate("/login");
      } else {
        setUid(res?.user?.uid);
        if (res.user.roles && res.user.roles.length > 0) {
          setRole(localStorage.getItem("baglini_role") || res.user.roles[0]);
          const isLogin =
            (localStorage.getItem("isBagliniLogin") || "") === "true";
          if (res.user.roles[0].includes(roles.admin) && isLogin) {
            localStorage.removeItem("isBagliniLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.installer) && isLogin) {
            localStorage.removeItem("isBagliniLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.customer) && isLogin) {
            localStorage.removeItem("isBagliniLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.company) && isLogin) {
            localStorage.removeItem("isBagliniLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.maintainer) && isLogin) {
            localStorage.removeItem("isBagliniLogin");
            navigate(mainUrl);
          }
          if (res.user.roles[0].includes(roles.respMaintainer) && isLogin) {
            localStorage.removeItem("isBagliniLogin");
            navigate(mainUrl);
          }
        }
      }
      setLoading(false);
    });
  }, []);

  const HomeView = () => {
    return (
      <HomePage
        role={localStorage.getItem("baglini_role") || role}
        setBreadcrumb={setBreadcrumb}
      />
    );
  };

  const ProfileView = () => {
    return <Profile setBreadcrumb={setBreadcrumb} />;
  };

  const CompanyListToChartView = () => {
    return (
      <CompaniesOverview
        title="companyOverview"
        role={role}
        setBreadcrumb={setBreadcrumb}
        link={chartPageUrl}
      />
    );
  };

  const ElevatorListToChartView = () => {
    return (
      <ElevatorListToChart
        companyInfo={
          role !== roles.installer &&
          role !== roles.customer &&
          role !== roles.company &&
          role !== roles.maintainer &&
          role !== roles.respMaintainer
        }
        previousLink={companyListToChartUrl}
        previousTitle="companyOverview"
        nextLink={chartPageUrl}
        setBreadcrumb={setBreadcrumb}
        title="overviewElevators"
        role={role}
      />
    );
  };

  const ChartPageView = () => {
    return (
      <InstallationPage
        companyInfo={
          role !== roles.installer &&
          role !== roles.customer &&
          role !== roles.company &&
          role !== roles.maintainer &&
          role !== roles.respMaintainer
        }
        role={role}
        setBreadcrumb={setBreadcrumb}
      />
    );
  };

  const AlarmPageView = () => {
    return <Alarm setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const CompanyListToDownloadDataView = () => {
    return (
      <Overview
        title="dataHistory"
        superadmin={role === roles.superadmin}
        admin={role === roles.admin}
        setBreadcrumb={setBreadcrumb}
        link={downloadPageUrl}
      />
    );
  };

  const ElevatorListToDownloadDataView = () => {
    return (
      <ElevatorListToChart
        companyInfo={
          role !== roles.installer &&
          role !== roles.customer &&
          role !== roles.company &&
          role !== roles.maintainer &&
          role !== roles.respMaintainer
        }
        previousLink={companyListToDownloadDataUrl}
        previousTitle="dataHistory"
        nextLink={downloadPageUrl}
        setBreadcrumb={setBreadcrumb}
        title="dataHistory"
        role={role}
      />
    );
  };

  const DownloadPageView = () => {
    return (
      <DataHistory
        companyInfo={
          role !== roles.installer &&
          role !== roles.customer &&
          role !== roles.company &&
          role !== roles.maintainer &&
          role !== roles.respMaintainer
        }
        setBreadcrumb={setBreadcrumb}
      />
    );
  };

  const ParamsElevatorView = () => {
    return <ParamsElevator setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const TestBatteriaTamponeElevatorView = () => {
    return <TestBatteriaTampone setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const CreateElevatorView = () => {
    return <CreateElevator setBreadcrumb={setBreadcrumb} actualRole={role} />;
  };

  const ElevatorListToUpdateView = () => {
    return (
      <ElevatorsList
        link={updateElevatorUrl}
        setBreadcrumb={setBreadcrumb}
        role={role}
      />
    );
  };

  const UpdateElevatorView = () => {
    return <UpdateElevator setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const ConfigurationEndView = () => {
    return <ConfigurationEnd setBreadcrumb={setBreadcrumb} />;
  };

  const ConfigurationView = () => {
    return (
      <Configuration
        setBreadcrumb={setBreadcrumb}
        setConfiguratorRunning={setConfiguratorRunning}
        role={role}
      />
    );
  };

  const ConfigurationParamsView = () => {
    return <ConfigurationParams setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const AssignMantainerView = () => {
    return (
      <AssignElevator
        setBreadcrumb={setBreadcrumb}
        types={[{ label: t("mantainer"), value: roles.maintainer }]}
        role={role}
      />
    );
  };

  const ElevatorsHistoryView = () => {
    return <ElevatorHistory setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const UsersListView = () => {
    return (
      <UsersList
        setBreadcrumb={setBreadcrumb}
        link={updateUserUrl}
        title="updateUser"
        types={
          [...availableListRoles(role, t)] as (
            | "customers"
            | "manutentori"
            | "responsabili"
            | "installers"
            | "aziende"
            | "admins"
          )[]
        }
        uid={uid}
        actualRole={role}
      />
    );
  };

  const UpdateUserView = () => {
    return (
      <UpdateUser
        link={usersListUrl}
        setBreadcrumb={setBreadcrumb}
        title="updateUser"
        role={role}
        uid={uid}
      />
    );
  };

  const AssignUserView = () => {
    return (
      <AssignElevator
        setBreadcrumb={setBreadcrumb}
        types={[
          { label: t("mantainerResponsible"), value: roles.respMaintainer },
          { label: t("mantainer"), value: roles.maintainer },
          { label: t("endUser"), value: roles.customer },
        ]}
        role={role}
      />
    );
  };

  const CreateUsersView = () => {
    return (
      <CreateUser
        roles={[...availableRoles(role, t)]}
        setBreadcrumb={setBreadcrumb}
        title="createUser"
        actualRole={role}
        uid={uid}
      />
    );
  };

  const DeleteElevatorsView = () => {
    return <DeleteElevators setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const CreateMaintenanceView = () => {
    return <CreateMaintenance setBreadcrumb={setBreadcrumb} />;
  };

  const MaintenanceListView = () => {
    return <MaintenanceList setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const MaintenanceHistoryView = () => {
    return <MaintenanceList setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const AssignBoardTypeView = () => {
    return <AssignBoard setBreadcrumb={setBreadcrumb} />;
  };

  const AlarmsThresholdView = () => {
    return <AlarmsThresholds setBreadcrumb={setBreadcrumb} />;
  };

  const AzMaintenanceView = () => {
    return <AzMaintenance setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const AzUserMaintenanceView = () => {
    return <AzUserMaintenance setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const DownloadDataView = () => {
    return <DownloadData setBreadcrumb={setBreadcrumb} role={role} />;
  };

  const WifiConfigurationView = () => {
    return <WifiConfiguration setBreadcrumb={setBreadcrumb} role={role} />;
  };

  return (
    <>
      <Header
        configuratorRunning={configuratorRunning}
        breadcrumbs={breadcrumb}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
        isMobile={isMobile}
      />
      <Navbar
        configuratorRunning={configuratorRunning}
        SetNavbarIsCollapsed={setNavbarCollapsed}
        navbar_collapsed={navbarCollapsed}
        isMobile={isMobile}
      />
      {loading ? (
        <CustomLoading />
      ) : (
        <div style={{ marginLeft: isMobile ? "0px" : "230px" }}>
          <Routes>
            <Route path={routes.mainPage} element={HomeView()} />
            <Route path={routes.profile} element={ProfileView()} />
            <Route
              path={routes.companyListToChart}
              element={CompanyListToChartView()}
            />
            <Route
              path={routes.elevatorListToChart}
              element={ElevatorListToChartView()}
            />
            <Route path={routes.chartPage} element={ChartPageView()} />
            <Route path={routes.alarms} element={AlarmPageView()} />
            <Route
              path={routes.companyListToDownloadData}
              element={CompanyListToDownloadDataView()}
            />
            <Route
              path={routes.elevatorListToDownloadData}
              element={ElevatorListToDownloadDataView()}
            />
            <Route path={routes.downloadPage} element={DownloadPageView()} />
            <Route
              path={routes.paramsElevator}
              element={ParamsElevatorView()}
            />
            <Route
              path={routes.testBatteriaTamponeElevator}
              element={TestBatteriaTamponeElevatorView()}
            />
            <Route
              path={routes.createElevator}
              element={CreateElevatorView()}
            />
            <Route
              path={routes.elevatorListToUpdate}
              element={ElevatorListToUpdateView()}
            />
            <Route
              path={routes.updateElevator}
              element={UpdateElevatorView()}
            />
            <Route path={routes.configuration} element={ConfigurationView()} />
            <Route
              path={routes.configurationEnd}
              element={ConfigurationEndView()}
            />
            <Route
              path={routes.configurationParams}
              element={ConfigurationParamsView()}
            />
            <Route
              path={routes.assignMantainer}
              element={AssignMantainerView()}
            />
            <Route
              path={routes.elevatorsHistory}
              element={ElevatorsHistoryView()}
            />
            <Route path={routes.createUsers} element={CreateUsersView()} />
            <Route path={routes.usersList} element={UsersListView()} />
            <Route path={routes.updateUser} element={UpdateUserView()} />
            <Route path={routes.assignUser} element={AssignUserView()} />
            <Route
              path={routes.deleteElevator}
              element={DeleteElevatorsView()}
            />
            <Route
              path={routes.createMaintenance}
              element={CreateMaintenanceView()}
            />
            <Route
              path={routes.maintenanceList}
              element={MaintenanceListView()}
            />
            <Route
              path={routes.maintenanceHistory}
              element={MaintenanceHistoryView()}
            />
            <Route
              path={routes.assignBoardType}
              element={AssignBoardTypeView()}
            />
            <Route path={routes.azMaintenance} element={AzMaintenanceView()} />
            <Route
              path={routes.azUserMaintenance}
              element={AzUserMaintenanceView()}
            />
            <Route
              path={routes.alarmsThreshold}
              element={AlarmsThresholdView()}
            />
            <Route path={routes.downloadData} element={DownloadDataView()} />
            <Route
              path={routes.wifiConfiguration}
              element={WifiConfigurationView()}
            />
          </Routes>
        </div>
      )}
    </>
  );
};

export default MainPage;
